import { useCallback } from "react";
import * as R from "ramda";
import { useQueryClient } from "react-query";

import { useSession } from "contexts/session";
import keys from "queries/keys";
import resourceOperations from "queries/resourceOperations";

const operation = resourceOperations({
  basePath: "/api/users",
  baseKey: "users",
  dataKey: "user",
});

export const useUpdateUser = (id: number, { onSuccess = R.T, ...opts } = {}) => {
  const {
    query: { invalidate: invalidateSession },
  } = useSession();
  const queryClient = useQueryClient();
  return operation.useUpdate(id, {
    ...opts,
    async onSuccess(updatedUser) {
      queryClient.setQueryData(keys.users(id), updatedUser);
      await invalidateSession("useUpdateUser");
      return onSuccess(updatedUser);
    },
  });
};

export const useAcceptTermsAndConditions = (userId: number, opts = {}) => {
  const { mutateAsync } = useUpdateUser(userId, opts);
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await mutateAsync({ terms_and_conditions: true });
    await queryClient.invalidateQueries();

    // HACK: remove once we use react-query for everything; until then, we have requests initiated by api.js which may have failed, no way to know which ones or easily reload them, for now, reload:
    window.location.reload();
  }, [queryClient, mutateAsync]);
};
