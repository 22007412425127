import * as R from "ramda";
import { createSelector } from "reselect";

import { SET_REQUIREMENTS, UPDATE_REQUIREMENT } from "actions";
import { EMPTY_ARRAY } from "utils/func";

import { selectFieldsIndexedByID } from "./fields";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_REQUIREMENTS: {
      return action.payload;
    }
    case UPDATE_REQUIREMENT: {
      const { id } = action.payload;
      const newRequirement = R.mergeDeepLeft(action.payload, R.propOr({}, id, state));
      return R.assoc(id, newRequirement, state);
    }
    default:
      return state;
  }
};

export const selectRequirementsIndexed = R.prop("requirements");

export const selectRequirements = createSelector(selectRequirementsIndexed, R.values);

export const selectRequirementByID = (state, requirementID) =>
  R.path(["requirements", requirementID], state);
export const selectRequirementsByID = (state, IDs) =>
  R.props(R.map(String, IDs))(state.requirements);
export const selectRequirementSlugByID = (state, id) => R.path([id, "slug"], state.requirements);
export const selectRequirementBySlug = (state, slug) =>
  R.find(R.propEq(slug, "slug"), R.values(state.requirements));
export const selectRequirementByKey = (state, key) =>
  R.find(R.propEq(key, "key"), R.values(state.requirements));
export const selectSectionsWithFieldsForRequirement = createSelector(
  (_, requirement) => R.propOr([], "sections", requirement),
  selectFieldsIndexedByID,
  (sections, fields) => R.map((s) => R.assoc("fields", R.props(s.field_ids, fields), s), sections),
);

const ScopingSection = {
  name: "Scoping",
  key: "scoping",
  type: "RequirementSection",
};

const FeeScopingSection = {
  name: "Fee Scoping",
  key: "fee_scoping",
  type: "RequirementSection",
};

export const getSectionByID = (requirement, sectionID) => {
  switch (sectionID) {
    case "scoping": {
      return ScopingSection;
    }
    case "fee_scoping": {
      return FeeScopingSection;
    }
    default:
      return R.compose(R.find(R.propEq(sectionID, "id")), R.propOr([], "sections"))(requirement);
  }
};

export const getFieldIDs = R.propOr([], "field_ids");
export const getFeeIDs = R.propOr([], "fee_ids");
export const getSlug = R.prop("slug");
export const getIdentifier = (requirement) =>
  `requirement:${getSlug(requirement).replace(/-/g, "_")}`;
export const getName = R.prop("name");
export const getDepartmentID = R.prop("department_id");
export const getDescription = R.prop("description");
export const getProcessingTime = R.prop("processing_time_markdown");
export const getNextStepsMarkdown = R.prop("next_steps");
export const getBeforeYouApplyMarkdown = R.prop("before_you_apply_markdown");
export const isIntegrated = R.propEq("integrated", "application_method");
export const isAccelaCitizenAccess = R.propEq("accela_citizen_access", "application_method");
export const isMailIn = R.propEq("mail_in", "application_method");
export const isInPerson = R.propEq("in_person", "application_method");
export const isFreeform = R.propEq("freeform", "application_method");
export const isStandaloneFee = R.propEq("standalone_fee", "application_method");
export const isExternalLink = R.propEq("external_link", "application_method");
export const getPrintableFormURL = R.prop("pdf_url");
export const getExternalLink = R.prop("external_link");
export const getDownloadURL = (requirement) =>
  isExternalLink(requirement) ? getExternalLink(requirement) : getPrintableFormURL(requirement);

export const getRequirementFees = (requirement, fees) => {
  const feeIDs = getFeeIDs(requirement);
  return R.filter((fee) => R.includes(fee.id, feeIDs), fees);
};

export const getRequirementStepCount = (requirement) => (requirement.payable ? 3 : 2);
export const getDisclaimer = R.prop("disclaimer");
export const getPostingInstructions = R.prop("posting_instructions");
export const getIssuerName = R.prop("issuer_name");
export const getIssuerTitle = R.prop("issuer_title");
export const getSignatureURL = R.prop("issuer_signature_url");
export const getIssuedRequirementFieldIDs = R.prop("issued_requirement_field_ids");
export const getIssuedRequirementSealURL = R.prop("issued_requirement_seal_url");
export const isPayableOnIssuance = R.prop("payable_on_issuance");

export const getDaysToSubmit = R.prop("days_to_submit");

export const filterRequirementsByDepartmentID = R.curry((departmentID, requirements) =>
  R.filter(R.pipe(getDepartmentID, R.equals(departmentID)), requirements),
);
export const isPayable = R.propOr(false, "payable");
export const isInPersonPayable = R.propIs(Number, "in_person_payable_account_id");
export const isIssuable = R.propOr(false, "issuable");

export const getRequirementOptions = (displayProp) => (requirements) =>
  R.map(
    (requirement) => ({
      display: R.prop(displayProp, requirement),
      value: requirement.id,
    }),
    R.sortBy(R.prop(displayProp), requirements),
  );

const selectRequirementOptions = (displayProp) => (state) =>
  getRequirementOptions(displayProp)(selectRequirements(state));

export const selectRequirementOptionsForConfig = selectRequirementOptions("admin_name");
export const selectRequirementOptionsForAdmin = selectRequirementOptions("name");
export const getRequirementOptionsForAdmin = getRequirementOptions("name");
export const hideTenantNameOnIssuedRequirement = R.prop("hide_tenant_name_on_issued_requirement");
export const hideDepartmentNameOnIssuedRequirement = R.prop(
  "hide_department_name_on_issued_requirement",
);

export const getReviews = R.propOr(EMPTY_ARRAY, "requirement_reviews");
export const getCompletableReviews = R.pipe(getReviews, R.filter(R.prop("can_complete")));
export const getRenewalSchedule = R.prop("renewal_schedule");
export const doesRenew = R.prop("renews");

export const selectRequirementNamesBySlug = R.compose(
  R.map(getName),
  R.indexBy(getSlug),
  selectRequirements,
);

export const selectIntegratedRequirements = R.compose(R.filter(isIntegrated), selectRequirements);

export const getCallToAction = R.prop("call_to_action");
