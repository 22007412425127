import { useMutation, useQuery } from "react-query";

import ocClient from "services/ocClient";

export const UNAUTHENTICATED = Symbol.for("unauthenticated");
export const PASSWORD_REQUIRED = Symbol.for("password_required");
export const REGISTRATION_REQUIRED = Symbol.for("registration_required");
export const OKTA_REQUIRED = Symbol.for("okta_required");
export const FORGOT_PASSWORD = Symbol.for("forgot");
export const INVITED = Symbol.for("invited");

type AuthUser = {
  email: string;
  token: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  terms_and_conditions: boolean;
};

export const useAuthn = (opts = {}) =>
  useMutation(
    async ({ email }: AuthUser) => {
      const {
        data: { status, ...data },
      } = await ocClient.post("/api/authn", { email });
      return {
        ...data,
        status: Symbol.for(status),
      };
    },
    {
      mutationKey: "authn",
      ...opts,
    },
  );

export const useApplicantAuthentication = (opts = {}) =>
  useMutation(
    async ({ email, password }: AuthUser) => {
      const { data } = await ocClient.post("/api/applicants/login", {
        user: { email, password },
      });
      return data;
    },
    {
      mutationKey: "applicant-login",
      ...opts,
    },
  );

export const useCreateApplicant = (opts = {}) =>
  useMutation(
    async ({
      email,
      password,
      first_name,
      last_name,
      phone_number,
      terms_and_conditions,
    }: AuthUser) => {
      const { data } = await ocClient.post("/api/applicants", {
        user: {
          email,
          password,
          first_name,
          last_name,
          phone_number,
          terms_and_conditions,
        },
      });
      return data;
    },
    { mutationKey: "create-applicant", ...opts },
  );

export const useUpdateApplicant = (id: number, opts = {}) =>
  useMutation(
    async ({ password, phone_number, terms_and_conditions }: AuthUser) => {
      const { data } = await ocClient.put(`/api/applicants/${id}`, {
        user: {
          password,
          phone_number,
          terms_and_conditions,
        },
      });
      return data;
    },
    { mutationKey: "update-applicant", ...opts },
  );

export const useRequestPasswordReset = (opts = {}) =>
  useMutation(
    async ({ email }: AuthUser) => {
      const { data } = await ocClient.post("/api/applicants/reset_password_email", {
        email,
      });
      return data;
    },
    { mutationKey: "request-password-reset", ...opts },
  );

export const useVerifyPasswordResetToken = (token: string, opts = {}) =>
  useQuery({
    async queryFn() {
      // This is a POST, for some reason, but it is not a mutation. It only confirms the token is still valid
      const { data } = await ocClient.post("/api/applicants/verify_reset_password_token", {
        token,
      });
      return data;
    },
    queryKey: ["password-reset", token],
    retry: 0,
    refetchOnWindowFocus: false,
    ...opts,
  });

export const useResetPassword = (opts = {}) =>
  useMutation(
    async ({ token, password, terms_and_conditions }: AuthUser) => {
      const { data } = await ocClient.post("/api/applicants/reset_password", {
        password,
        token,
        terms_and_conditions,
      });
      return data;
    },
    { mutationKey: "consume-password-reset-token", ...opts },
  );
