import * as R from "ramda";

import { User } from "types/adminEntities";

import resourceOperations, { ReadOptions } from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/admin/users",
  baseKey: "users",
  versioned: false,
});

type IndexedList<T> = { [id: string | number]: T };
type UsersIndexResponse = IndexedList<User>;

interface UseUsersOptions extends Omit<ReadOptions<User[], UsersIndexResponse>, "select"> {
  select?: (data: User[]) => User[];
}
export const useUsers = ({ select, ...opts }: UseUsersOptions = {}) =>
  operation.useRead<User[], UsersIndexResponse>(null, {
    ...opts,
    select: R.pipe(R.values, select || R.identity),
  });
