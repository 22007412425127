/**
 * This module handles the invalidation for our react-query based state mangement system.
 *
 * Paths added to queries/keys will be used to generate a matching basic invalidation
 * that will invalidate the general/admin data structures
 *
 * In more complex or non-standard cases you can also write each of these
 * parts yourself or customize them.
 *
 * Usage:
 *   import invalidations from "queries/invalidations";
 *   invalidations.projects(queryClient, 1);
 *   invalidations.projects(queryClient);  // all projects
 *
 * This covers use cases like:
 *   invalidate root data for admin/general.
 *   invalidate one thing for admin/general.
 *   share complex invalidation logic between call sites (both admin/general)
 */

import { QueryClient } from "react-query";

import keys from "queries/keys";

type Keys = typeof keys;
type KeysWithoutAdmin = Omit<Keys, "admin">;

type InvalidationFunction = (queryClient: QueryClient, ...args: any[]) => void;

const basicInvalidation =
  (key: keyof KeysWithoutAdmin): InvalidationFunction =>
  (queryClient: QueryClient, ...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // FIXME(rtlong)
    queryClient.invalidateQueries(keys[key](...args));
    if (key in keys.admin) {
      queryClient.invalidateQueries((keys.admin as any)[key](...args));
    }
  };

const invalidations: Record<keyof KeysWithoutAdmin, InvalidationFunction> = Object.keys(
  keys,
).reduce((acc, key) => {
  if (key !== "admin") {
    acc[key as keyof KeysWithoutAdmin] = basicInvalidation(key as keyof KeysWithoutAdmin);
  }
  return acc;
}, {} as Record<keyof KeysWithoutAdmin, InvalidationFunction>);

// Override specific invalidations
invalidations.requirementApplicationReviews = (
  queryClient: QueryClient,
  projectID: number,
  raID: number,
) => {
  queryClient.invalidateQueries(keys.projects(projectID));
  queryClient.invalidateQueries(keys.admin.projects(projectID));
  queryClient.invalidateQueries(keys.requirementApplications(projectID, raID));
  queryClient.invalidateQueries(keys.requirementApplicationReviews(projectID, raID));
  queryClient.invalidateQueries(keys.admin.requirementApplicationReviews(projectID, raID));
};

export default invalidations;
