import React, { useCallback, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import { ButtonDiv } from "components/accessibility/Div";
import useToggleState from "hooks/useToggleState";
import { getEnumLabelByCode } from "reducers/fields";
import { handleSpacebarOrEnter } from "utils/accessibility";
import { enumPropType, errorPropType, fieldPropType } from "utils/sharedPropTypes";

import styles from "./SelectField.scss";
import { DisabledTextField } from "./TextField";
import { immediateValue } from "./behaviors";

const toRows = (onClick, selectedEnum, e) => {
  const { code, label, description } = e;
  const isSelected = selectedEnum && selectedEnum.label === label;

  return (
    <ButtonDiv
      data-select-option
      role="option"
      className={classnames(styles.option, { [styles.active]: isSelected && label })}
      key={`option-${code}`}
      value={code}
      onMouseDown={() => onClick(code || null, e)}
      aria-selected={isSelected}
    >
      <span>{label || ""}</span>
      <div>{description}</div>
    </ButtonDiv>
  );
};

const SelectInputBox = ({ suppressIcon, active, text }) => (
  <div className={styles.inputBox}>
    {text}
    {!suppressIcon && (active ? <Icon icon="caret-up" /> : <Icon icon="caret-down" />)}
  </div>
);

SelectInputBox.propTypes = {
  text: PropTypes.element,
  active: PropTypes.bool,
  suppressIcon: PropTypes.bool,
};

export const SelectField = ({
  value,
  active: activeInitial,
  field,
  enumOptions,
  placeholder,
  includeBlank,
  onChange,
  suppressIcon,
  error,
  required,
  withoutSpacing,
  onBlur,
}) => {
  const { value: active, toggle: toggleActive, setOff: deactivate } = useToggleState(activeInitial);
  const container = useRef();
  const handleBlur = useCallback(
    (e) => {
      if (!container.current.contains(e.relatedTarget)) {
        deactivate();
        if (onBlur) onBlur(e);
      }
    },
    [container, deactivate, onBlur],
  );

  const handleClick = useCallback(
    (e) => {
      toggleActive();
      e.stopPropagation();
    },
    [toggleActive],
  );

  const prependBlank = includeBlank || R.path(["ui", "form_input", "include_blank"], field);
  const enums = prependBlank ? [{}, ...(enumOptions || field.enum)] : enumOptions || field.enum;
  const selectedEnum = R.find(R.propEq(value, "code"), enums);

  const selectorText = selectedEnum ? (
    <span className={styles.selectedText}>{selectedEnum.label}</span>
  ) : (
    <span className={styles.placeholder}>{placeholder || field.placeholder}</span>
  );

  const optionComponents = (
    <div className={styles.options}>
      <SelectInputBox suppressIcon={suppressIcon} active text={selectorText} />
      {R.map(R.partial(toRows, [onChange, selectedEnum]), enums)}
    </div>
  );

  // We duplicate the code in accessibility/Div here because we need a ref for checking
  // whether the blur leaves this component
  return (
    <div
      role="button menu"
      tabIndex={0}
      className={classnames(styles.container, {
        [styles.active]: active,
        [styles.withoutSpacing]: withoutSpacing,
      })}
      ref={container}
      onClick={handleClick}
      onKeyDown={handleSpacebarOrEnter(handleClick)}
      onBlur={handleBlur}
      aria-expanded={active}
      aria-invalid={!!error}
      aria-required={!!required}
      data-select
    >
      <SelectInputBox suppressIcon={suppressIcon} active={active} text={selectorText} />
      {optionComponents}
    </div>
  );
};
SelectField.propTypes = {
  enumOptions: PropTypes.arrayOf(enumPropType),
  placeholder: PropTypes.string,
  includeBlank: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field: fieldPropType,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: errorPropType,
  suppressIcon: PropTypes.bool,
  required: PropTypes.bool,
  withoutSpacing: PropTypes.bool,
};

export const DisabledSelectField = ({ field, value }) => (
  <DisabledTextField value={value && getEnumLabelByCode(field, value)} />
);
DisabledSelectField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  field: fieldPropType,
};
export const disabled = DisabledSelectField;

export default immediateValue(SelectField);
