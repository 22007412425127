import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";
import Select from "react-select";
import { defaultProps } from "recompose";
import { Field } from "redux-form";

import Tooltip from "components/Tooltip";
import { CalendarFieldComponent } from "components/project_form/CalendarField";
import { CheckboxFieldComponent } from "components/project_form/CheckboxField";
import ErrorMessage from "components/project_form/ErrorMessage";
import { TextAreaFieldComponent } from "components/project_form/TextAreaField";
import { TextFieldComponent } from "components/project_form/TextField";
import withReduxForm from "containers/withReduxForm";
import { hoc } from "utils/hoc";
import {
  childrenPropType,
  classNamePropType,
  optionsPropType,
  valuePropType,
} from "utils/sharedPropTypes";

import styles from "./Fields.scss";

const withBlank = R.prepend({ label: "", value: undefined });

const WithOptionallyLabeledField = ({
  Component,
  name,
  label,
  required,
  inlineLabel,
  helpText,
  ...props
}) => {
  const { disabled } = props;
  if (!label) return <Field component={Component} name={name} {...props} />;

  return (
    <div className={styles.wrapper}>
      <label
        htmlFor={name}
        className={classnames({
          [styles.inline]: inlineLabel,
          [styles.disabled]: disabled,
        })}
      >
        {label}
        {required && "*"}
        {helpText && (
          <span className={styles.help}>
            <Tooltip message={helpText} icon="info-circle" />
          </span>
        )}
        <Field component={Component} name={name} {...props} />
      </label>
    </div>
  );
};
WithOptionallyLabeledField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inlineLabel: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};
const withOptionalLabel = hoc(WithOptionallyLabeledField);

const FieldGroup = ({ alignTop, children }) => (
  <div className={classnames(styles.fieldGroup, { [styles.alignTop]: alignTop })}>{children}</div>
);
FieldGroup.propTypes = { children: childrenPropType, alignTop: PropTypes.bool };

export const ConnectedTextField = withReduxForm(TextFieldComponent);
export const ConnectedCheckboxField = withReduxForm(CheckboxFieldComponent);
export const ConnectedTextAreaField = withReduxForm(TextAreaFieldComponent);
export const TextField = withOptionalLabel(ConnectedTextField);

export const CheckboxField = R.compose(
  defaultProps({ inlineLabel: true }),
  withOptionalLabel,
)(ConnectedCheckboxField);

export const SelectInput = ({ compact, ...props }) => {
  const {
    includeBlank,
    options,
    clearable = false,
    rawValue, // Allows us to pass in a value like a normal select element rather than the non-sensical option object react-select requires
    input: { value: inputValue, onChange, onBlur },
    meta: { error },
  } = props;

  let value = inputValue;

  if (rawValue) {
    value = options.find((o) => o.value === rawValue);
  }

  return (
    <div className={classnames({ [styles.selectField]: !compact })}>
      <Select
        {...props}
        value={value}
        onChange={(v) => onChange(v ? v.value : null)}
        onBlur={() => onBlur(value)}
        options={includeBlank ? withBlank(options) : options}
        clearable={clearable}
        classNamePrefix="react-select"
      />
      {error && (
        <p data-error className={styles.error}>
          {error}
        </p>
      )}
    </div>
  );
};
SelectInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }),
  options: optionsPropType.isRequired,
  clearable: PropTypes.bool,
  compact: PropTypes.bool,
  includeBlank: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
};

export const SelectField = withOptionalLabel(SelectInput);

export const FieldGroupRow = ({ children }) => (
  <div className={styles.fieldGroupRow}>{children}</div>
);
FieldGroupRow.propTypes = { children: childrenPropType };

const CheckboxGroupFields = ({
  input: { value, name, onChange, onBlur, onFocus },
  meta,
  options,
  className,
  disabled,
}) => {
  const { error } = meta;
  const checkboxes = options.map(({ label, value: val }, index) => {
    const handleChange = (event) => {
      const arr = [...value];
      if (event.target.checked) {
        arr.push(val);
      } else {
        arr.splice(arr.indexOf(val), 1);
      }
      onBlur(arr);
      return onChange(arr);
    };
    const checked = value.includes(val);
    const checkboxName = `${name}${index}`;
    return (
      <label
        htmlFor={checkboxName}
        key={`checkbox-${checkboxName}`}
        className={classnames(className, styles.inline)}
      >
        <ConnectedCheckboxField
          input={{
            name: checkboxName,
            value: checked,
            onChange: handleChange,
            onFocus,
          }}
          meta={{ ...meta, error: null }}
          disabled={disabled}
          labelProps={{ field: { id: "fug", field_label: label } }}
        >
          <span>{label}</span>
        </ConnectedCheckboxField>
      </label>
    );
  });

  return (
    <div data-checkbox-group={name} data-disabled={disabled}>
      <FieldGroup>{checkboxes}</FieldGroup>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};
CheckboxGroupFields.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(valuePropType)]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
  options: optionsPropType,
  disabled: PropTypes.bool,
  className: classNamePropType,
};

export const CheckboxGroupField = ({ name, ...props }) => (
  <Field component={CheckboxGroupFields} name={name} {...props} />
);
CheckboxGroupField.propTypes = { name: PropTypes.string };

export const ConnectedCalendarField = withReduxForm(CalendarFieldComponent);
export const CalendarField = withOptionalLabel(ConnectedCalendarField);
