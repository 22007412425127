import { UseMutationOptions, useMutation, useQuery, useQueryClient } from "react-query";

import invalidations from "queries/invalidations";
import keys from "queries/keys";
import ocClient from "services/ocClient";

export function useRequirementApplication(projectID: number, id: number) {
  return useQuery(
    keys.requirementApplications(projectID, id),
    async () => {
      const response = await ocClient.get(`/api/requirement_applications/${id}`);
      return response.data;
    },
    { staleTime: 0 },
  );
}

// TODO: Migrate to admin/queries/requirementApplications.ts
export function useAdminRequirementApplication(projectID: number, id: number) {
  return useQuery(
    keys.admin.requirementApplications(projectID, id),
    async () => {
      const response = await ocClient.get(`/api/admin/requirement_applications/${id}`);
      return response.data;
    },
    { staleTime: 0 },
  );
}

export function useIssuedRequirementAssets(projectID: number, raID: number, opts = {}) {
  return useQuery(
    keys.requirementApplicationAssets(projectID, raID),
    async () => {
      const response = await ocClient.get(`/api/issued_requirements/${raID}`);
      return response.data;
    },
    { staleTime: 0, ...opts },
  );
}
// TODO: onError should handle auth, toc, etc
export const useMutateRequirementApplication = (opts: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (ra: any) => ocClient.put(`/api/admin/requirement_applications/${ra.id}`, ra),
    {
      onSuccess: (app: any) => {
        // TODO: use queryClient.setQueryData(path(ra.id), data) once return format is same as show
        invalidations.requirementApplications(queryClient, app.project_id, app.id);
      },
      ...opts,
    },
  );
};

export function useSubmitApplication(opts: UseMutationOptions) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => ocClient.put(`/api/requirement_applications/${id}/submit`),
    onSuccess: (app) => {
      invalidations.requirementApplications(queryClient, app.project_id, app.id);
      invalidations.projects(queryClient, app.project_id);
    },
    ...opts,
  });
}
