/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";

import { isProductionBuild } from "env";

if (!isProductionBuild) {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
    // trackExtraHooks: [[ReactRedux, "useSelector"]],
  });
}
