import * as R from "ramda";
import { connect } from "react-redux";

import { fetchRequirementApplication } from "actions";
import Process from "components/projects/requirement/Process";
import { withAnswerContextConsumer } from "containers/withAnswerContext";
import withRouterParams from "containers/withRouterParams";
import { getRequirementApplications } from "reducers/projects";
import { selectRequirementBySlug } from "reducers/requirements";

const mapStateToProps = (state, { requirementSlug, answerContext: { record: project } }) => {
  const requirement = selectRequirementBySlug(state, requirementSlug);
  const requirementApplications = getRequirementApplications(project);
  const requirementApplication = R.find(
    R.propEq(requirement.id, "requirement_id"),
    requirementApplications,
  );

  return { project, requirementApplication };
};

export default R.compose(
  withAnswerContextConsumer,
  withRouterParams(["requirementSlug"]),
  connect(mapStateToProps, { fetchRequirementApplication }),
)(Process);
