import { diffArrays, diffTrimmedLines } from "diff";
import * as R from "ramda";

import { Version } from "types/configEntities";
import { compact, isPresent, isString } from "utils/func";

import resourceOperations, { ReadOptions } from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/config/versions",
  baseKey: ["config", "versions"],
  dataKey: "version",
});

// TODO: should have a way to not only invalidate via pusher, but force other config users to update when HEAD changes

export const useVersions = (opts: ReadOptions<Version[]> = {}) =>
  operation.useRead<Version[]>(null, opts);

export const useVersion = (number: number, opts = {}) => {
  const queryResult = useVersions(opts);
  const { data: versions, isSuccess, isFetching } = queryResult;
  if (!isSuccess) return queryResult;

  const idx = R.findIndex(R.propEq(number, "number"), versions);
  if (idx === -1) {
    if (isFetching) return queryResult; // handle race-condition upon commit where HEAD+1 version is not present in current data but it's pending refresh
    throw new Error(`Version with number ${number} not found`);
  }

  // eslint-disable-next-line prefer-const
  let [previous_version, next_version] = R.map(
    R.compose(R.prop("number"), R.flip(R.nth)(versions), R.add(idx)),
  )([1, -1]);
  if (idx === 0) next_version = null;
  return {
    ...queryResult,
    data: R.mergeLeft({ previous_version, next_version })(versions[idx]),
  };
};

export const useUpdateVersion = (id: number, opts = {}) => operation.useUpdate<Version>(id, opts);

export const useCommitVersion = (id: number, opts = {}) =>
  operation.useUpdate<Version>([String(id), "commit"], opts);

export const useConfigVersionHealth = (versionNumber, healthType, opts = {}) =>
  operation.useRead(`${versionNumber}/config_health/${healthType}`, opts);

export interface SerializationError {
  class: string;
  message: string;
  backtrace: string[];
  path: string;
  uuid: string;
}

type ArrayDiff = Array<{
  added: boolean;
  removed: boolean;
  value: string[];
}>;
type TextDiff = Array<{
  added: boolean;
  removed: boolean;
  value: string;
}>;

interface LabeledDiff {
  key: string;
  fullPath: string[];
  label?: string;
  isRemoved?: boolean;
  isAdded?: boolean;
}
export interface LabeledArrayDiff extends LabeledDiff {
  diff: ArrayDiff;
  isArrayDiff: true;
}
export interface LabeledTextDiff extends LabeledDiff {
  diff: TextDiff;
  isDiff: true;
}
// eslint-disable-next-line no-use-before-define
export type DiffTreeNode = LabeledObjectDiff | LabeledArrayDiff | LabeledTextDiff;

export interface LabeledObjectDiff extends LabeledDiff {
  diff: DiffTreeNode[];
}

type SerializedConfigItem = object;
export type DiffableSerializedConfigItem = {
  added: boolean;
  removed: boolean;
  diffTree: DiffTreeNode;
};

export type SerializationErrors = Array<SerializationError>;

type ConfigVersionDiffApiResponse = {
  range: {
    this: number;
    previous: number;
  };
  changes: {
    [key: string]: [SerializedConfigItem, SerializedConfigItem];
  };
  serialization_errors: {
    this: SerializationErrors;
    previous: SerializationErrors;
  };
};

type DiffableSerializedConfig = {
  diffs: {
    [key: string]: DiffableSerializedConfigItem;
  };
  errors: SerializationErrors;
};

// given versionNumber, diff with previous verson. returns an object
export const useConfigVersionDiff = (versionNumber, opts = {}) =>
  operation.useRead<DiffableSerializedConfig, ConfigVersionDiffApiResponse>(
    `${versionNumber}/diff`,
    {
      ...opts,
      select({ changes, serialization_errors }) {
        const diffs = R.map(([before, after]) => {
          const diffTree = diffValues(before, after);
          return {
            added: R.isNil(before),
            removed: R.isNil(after),
            diffTree,
          };
        }, changes);

        return { diffs, errors: serialization_errors.this };
      },
    },
  );

const isCharDiffable = (thing) => isString(thing) || R.is(Number, thing) || R.is(Boolean, thing);
const canCharDiff = (before, after) => isCharDiffable(before) || isCharDiffable(after);
const prepareForDiff = R.cond([
  [R.isNil, R.always("")],
  [R.is(String), R.ifElse(R.isEmpty, R.always("<empty>"), R.identity)],
  [R.T, R.toString],
]);
const isNotNil = R.complement(R.isNil);

const canArrayDiff = (before, after) =>
  R.pipe(
    R.map(R.defaultTo([])),
    R.both(R.all(R.is(Array)), R.pipe(R.apply(R.union), R.all(isCharDiffable))),
  )([before, after]);

const labeledDiff = (diffChanges: TextDiff) =>
  ({ isDiff: true, diff: diffChanges } as LabeledTextDiff);
export const isDiff = (d: LabeledTextDiff) => !!d.isDiff;

const labeledArrayDiff = (diffChanges: ArrayDiff) =>
  ({ isArrayDiff: true, diff: diffChanges } as LabeledArrayDiff);
export const isArrayDiff = (d: LabeledArrayDiff) => !!d.isArrayDiff;

const isEmptyDiff = (changes) =>
  R.isEmpty(changes) || (changes.length === 1 && !(changes[0].added || changes[0].removed));

const diffValues = (before, after, path = []) => {
  if (!(isNotNil(before) || isNotNil(after))) return null;
  if (canCharDiff(before, after)) {
    const [preparedBefore, preparedAfter] = [prepareForDiff(before), prepareForDiff(after)];
    const changes = diffTrimmedLines(preparedBefore, preparedAfter);
    if (isEmptyDiff(changes)) return null; // ignore when no change present
    return labeledDiff(changes);
  }

  if (canArrayDiff(before, after)) {
    const changes = diffArrays(before || [], after || []);
    if (isEmptyDiff(changes)) return null; // ignore when no change present
    return labeledArrayDiff(changes);
  }

  return deepDiff(before, after, path);
};

const tryNumber = (str: string) => {
  const n = Number(str);
  if (Number.isNaN(n)) return str;
  return n;
};

// some entities in the tree don't have slugs, so we identify them only by their ID. When those things don't have any change in a name or title, it's difficult to understand which entity is being changed. This function tries to extract a label from the entity to help with that, which will be shown next to the ID in the diff tree, irrespective of the diff.
const tryExtractLabel = (after: object, before: object) => {
  const labelFns: Array<(o: object) => string | null> = [
    R.prop("name"),
    R.path(["translation", "en", "name"]),
    R.path(["translation", "en", "title"]),
    R.path(["translation", "en", "option_value"]),
    R.path(["translation", "en", "description"]),
  ];
  for (const fn of labelFns) {
    const result = fn(after) || fn(before);
    if (result != null) return result;
  }
  return null;
};

// when deepDiff is called without the path parameter, it is the root of the object, and there's definitely some difference to detect.
// this could not be assumed for any nested recursive calls
function deepDiff(before: object, after: object, path = []): LabeledObjectDiff {
  const beforeKeys: string[] = R.keys(before);
  const afterKeys: string[] = R.keys(after);
  const removedKeys = R.difference(beforeKeys, afterKeys);
  const addedKeys = R.difference(afterKeys, beforeKeys);
  const allKeys = R.union(beforeKeys, afterKeys);

  const nodes: DiffTreeNode[] = allKeys.map((key) => {
    const get = R.prop(key);
    const [beforeValue, afterValue] = [get(before), get(after)];
    const fullPath = R.append(key, path);
    const diff = diffValues(beforeValue, afterValue, fullPath);
    if (!diff) return null; // skip where both values are null
    diff.key = key;
    diff.fullPath = fullPath;
    if (R.includes(key, removedKeys)) {
      diff.isRemoved = true; // mark key as removed so it can be more clearly indicated in the UI
    }
    if (R.includes(key, addedKeys)) {
      diff.isAdded = true; // mark key as added so it can be more clearly indicated in the UI
    }
    // TODO move the tryExtractLabel behavior here instead of in the components, as here  we have the full before and after values. This requires some consideration of schema; it really only applies to object diffs with numeric keys. Could just set the `key` to the numeric + the label, but I'd prefer to represent the label using a slightly different visual cue so it's clearer that it's there to provide context and is not strictly part of the diff.
    const keyIsNumber = R.is(Number, tryNumber(key)); // we only attempt this when we have opaque keys, which so far is only numbers (usually IDs, sometimes an array index)
    if (!isDiff(diff as LabeledTextDiff) && !isArrayDiff(diff as LabeledArrayDiff) && keyIsNumber) {
      diff.label = tryExtractLabel(afterValue, beforeValue);
    }
    // TODO also consider moving the key sorting behavior here, as that is probably appropriate to be cached and not recalculated on every render
    if (isPresent(diff.diff)) return diff;
    return null;
  });

  const sorted: DiffTreeNode[] = R.pipe(
    compact,
    R.sortWith<DiffTreeNode>([
      R.ascend<DiffTreeNode>(
        R.pipe(
          R.cond([
            [isDiff, R.always(0)], // sort text diffs first
            [isArrayDiff, R.always(1)], // then array diffs
            [R.T, R.always(2)], // then nested objects
          ]),
        ),
      ),
      R.ascend(R.pipe(R.prop("key"), tryNumber)), // within each group, sort by key
    ]),
  )(nodes);
  return {
    diff: sorted,
    fullPath: path,
    key: R.last(path),
  };
}
