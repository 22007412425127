import React from "react";
import classnames from "classnames";

import styles from "./TelephoneLink.scss";

const usPhoneNumberPattern = /(?:\+?(1)[-. ]?)?\(?(\d{3})[). -]*(\d{3})[. -]?(\d{4})/;
const extractPhoneNumber = (number) => {
  if (!number) return null;
  return number.match(usPhoneNumberPattern)?.splice(1)?.join("");
};

const TelephoneLink = ({ number, className, ...props }) => {
  if (!number) return null;
  const callableNumber = extractPhoneNumber(number);
  if (!callableNumber) return number; // if the number is not in a valid format, just display it as is
  return (
    <a className={classnames(className, styles.link)} href={`tel:${callableNumber}`} {...props}>
      {number}
    </a>
  );
};
export default TelephoneLink;
