import IntlMessageFormat from "intl-messageformat";
import * as R from "ramda";

import translations from "translations";
import { selectLanguages } from "reducers/tenant";
import { selectLocale } from "reducers/ui";
import { reportError } from "services/errorReporter";

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const lookupTranslation = (locale) => (key) => R.path([locale, ...key.split(".")])(translations);

export const selectFormattedText = (state, key, values = {}) => {
  const locale = selectLocale(state);
  const rawText = lookupTranslation(locale)(key);
  if (!rawText) {
    reportError(new Error(`Missing translation: ${key}`), { params: { locale, key } });
    return key;
  }

  try {
    // FIXME: parsing this each time seems highly wasteful
    const message = new IntlMessageFormat(rawText, locale);
    return htmlDecode(message.format(values));
  } catch (e) {
    reportError(e, { params: { locale, key } });
    return key;
  }
};

export const selectLanguage = (state) => {
  const locale = selectLocale(state);
  const languages = selectLanguages(state);
  return R.compose(
    R.defaultTo({ name: "English", locale: "en" }),
    R.find(R.propEq(locale, "locale")),
  )(languages);
};
