import * as R from "ramda";

import { selectDepartmentsByID } from "reducers/departments";
import { selectCurrentUserId } from "reducers/session";
import { selectUserByID, selectUsers } from "reducers/users";

export {
  selectSessionIsLoaded,
  selectSessionHasUserId,
  selectIsAccelaConnected,
} from "reducers/session";

const getHasConfigAccess = R.propOr(false, "has_any_config_access?");
export const getContactPreferences = R.prop("contact_preference_attributes");
export const getCreatedAt = R.prop("created_at");
export const getDepartmentIDs = R.prop("department_ids");
export const getEmail = R.prop("email");
export const getFirstName = R.prop("first_name");
export const getHasAdminAccess = R.propOr(false, "has_any_admin_access?");
export const getID = R.prop("id");
export const getIsGuest = R.propOr(true, "is_guest");
export const getLastName = R.prop("last_name");
export const getName = R.prop("full_name");
export const getPhoneNumber = R.prop("phone_number");
export const getUserHash = R.prop("user_hash");
export const isOktaUser = R.propOr(false, "is_okta_user?");
export const requiresTacAcceptance = R.propOr(false, "requires_tos_acceptance?");
export const isOpenCounterStaff = R.propOr(false, "opencounter_staff?");
const getRoleNames = R.propOr([], "role_names");
export const hasRole = R.curry((role, user) => R.compose(R.includes(role), getRoleNames)(user));
export const isSuper = R.either(hasRole("superuser"), hasRole("superadmin"));
export const isSuperuser = hasRole("superuser");
export const isManager = hasRole("manager");

export const selectCurrentUser = (state) => selectUserByID(selectCurrentUserId(state))(state);
export const selectFullName = R.compose(getName, selectCurrentUser);
export const selectFirstName = R.compose(getFirstName, selectCurrentUser);

export const selectIsLoggedIn = R.pipe(selectCurrentUser, getIsGuest, R.not);
export const selectIsLoggedInAdmin = R.pipe(selectCurrentUser, getHasAdminAccess);
export const selectIsLoggedInConfig = R.pipe(selectCurrentUser, getHasConfigAccess);

export const selectUsersWithDepartments = (state) =>
  R.compose(
    R.map((user) =>
      R.mergeRight({ departments: selectDepartmentsByID(state, getDepartmentIDs(user)) }, user),
    ),
    R.values,
    selectUsers,
  )(state);
