import * as turf from "@turf/turf";
import { AxiosError } from "axios";
import Protobuf from "pbf";
import * as R from "ramda";
import { VectorTile } from "vector-tile";

import zoningApi from "./zoningApi";

export const fetchTileAt = async (tileType, tenantID, tileVersion, lat, lon) => {
  const z = 16;
  const x = lonToTile(lon, z);
  const y = latToTile(lat, z);

  try {
    if (Number.isNaN(x) || Number.isNaN(y)) {
      throw new Error("one or both coordinates is NaN");
    }
    const { data } = await zoningApi.client.get(
      `/api/mvt/${tenantID}/${tileType}/${z}/${x}/${y}.pbf?tileVersion=${tileVersion}`,
      {
        responseType: "arraybuffer",
      },
    );

    const pbf = new Protobuf(data);
    const tile = new VectorTile(pbf);
    const point = turf.point([lon, lat]);

    return { tile, point, x, y, z };
  } catch (e) {
    if (!(e instanceof AxiosError)) throw e;
    console.debug(e);
    return new ArrayBuffer(0);
  }
};

export const featuresOfTypeForPoint = (layerName, tile, tenantID, point, x, y, z) => {
  const layer = R.path(["layers", layerName], tile);
  if (!layer) return [];

  return R.reduce(
    (features, i) => {
      const feature = layer.feature(i);
      const geoJSON = feature.toGeoJSON(x, y, z);
      if (turf.booleanPointInPolygon(point, geoJSON.geometry))
        return R.append(feature.properties, features);
      return features;
    },
    [],
    R.range(0, layer.length),
  );
};

// FROM http://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
const lonToTile = (lon, zoom) => Math.floor(((lon + 180) / 360) * 2 ** zoom);

const latToTile = (lat, zoom) =>
  Math.floor(
    ((1 -
      Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) /
      2) *
      2 ** zoom,
  );
