import React, { useState } from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import ExternalLink from "components/ExternalLink";
import Text from "containers/Text";
import { selectGuideByID } from "reducers/guides";
import { getUseCodeSearchPosition } from "reducers/projects";
import { getMunicipalityType } from "reducers/tenant";
import analytics from "services/analytics";
import { USE_CODE_SELECTED } from "services/tracking";
import filterUseCodes from "utils/filterUseCodes";
import { mapIndexed } from "utils/func";
import { getFullName } from "utils/useCode";

import { DisabledTextField } from "./TextField";
import styles from "./UseCodeField.scss";
import Browser from "./use_code/Browser";
import BrowserToggle from "./use_code/BrowserToggle";
import SearchBox from "./use_code/SearchBox";
import UseCodeSearchResults from "./use_code/SearchResults";

const UseCodeField = ({
  onChange,
  onSave,
  simplified,
  value = {},
  field,
  required,
  answerContext: { record },
  tenant,
  locale,
}) => {
  const onUseCodeSelect = (useCode) => {
    analytics.track(USE_CODE_SELECTED, {
      useCode: R.prop("full_name", useCode),
      useCodeSlug: R.prop("slug", useCode),
      businessType: R.prop("query", value),
      useCodeSearchPosition: getUseCodeSearchPosition({
        use: useCode,
        results: R.propOr([], "results", value),
      }),
    });

    onChange(R.assoc("use", useCode, value));
    onSave();
  };
  const guide = useSelector((state) => selectGuideByID(state, R.prop("guide_id", record)));
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [lastQuery, setLastQuery] = useState("");

  let useCodes = tenant.use_codes;
  const useCodeFilter = guide?.use_code_filters || [];
  if (useCodeFilter.length > 0) useCodes = filterUseCodes(useCodes, useCodeFilter);

  // when there are a small number of available uses we just display them to be selected
  const searchThreshold = R.pathOr(10, ["ui", "form_input", "search_ui_threshold"], field);
  if (useCodes.length <= searchThreshold) {
    value.results = useCodes;
    return <UseCodeSearchResults value={value} onSelect={onUseCodeSelect} />;
  }

  const isEnglish = locale === "en";
  const municipalityType = getMunicipalityType(tenant);
  const searchResultSelected = R.any(
    R.propEq(R.path(["use", "slug"], value), "slug"),
    R.propOr([], "results", value),
  );
  const pristine = lastQuery === "";
  const results = R.prop("results", value);
  const resultsShown = (!pristine || !R.isEmpty(results)) && !searchInProgress;

  if (!isEnglish || simplified) {
    return (
      <Browser
        onUseCodeSelect={onUseCodeSelect}
        value={value?.use}
        guide={guide}
        tenant={tenant}
        isEnglish={isEnglish}
        simplified={simplified}
      />
    );
  }
  return (
    <div className={styles.container}>
      <div id="form-use-search-instructions" className={styles.instructions}>
        <Text t="forms.use_search_instructions" municipalityType={municipalityType} />
      </div>
      <div className={styles.searchContainer}>
        <SearchBox
          field={field}
          value={value}
          onChange={onChange}
          locale={locale}
          lastQuery={lastQuery}
          setLastQuery={setLastQuery}
          searchInProgress={searchInProgress}
          setSearchInProgress={setSearchInProgress}
          required={required}
          tenant={tenant}
          guide={guide}
        />
        {resultsShown ? <UseCodeSearchResults value={value} onSelect={onUseCodeSelect} /> : null}
      </div>

      {value || (resultsShown && !pristine) ? (
        <BrowserToggle
          value={searchResultSelected ? null : value?.use}
          tenant={tenant}
          onUseCodeSelect={onUseCodeSelect}
          guide={guide}
          isEnglish={isEnglish}
          compactView
        />
      ) : null}
    </div>
  );
};

export default UseCodeField;

export const DisabledUseCodeField = ({ value }) => {
  let query = R.prop("query", value);
  if (query) {
    query = `"${query}"`;
  }
  const selected = getFullName(value);

  let displayedValue;

  const values = R.reject(R.isNil, [query, selected]);
  if (!R.isEmpty(values)) {
    displayedValue = (
      <div>
        {mapIndexed(
          (v, index) => (
            <div key={index} className={index > 0 ? styles.disabledItem : null}>
              {v}
            </div>
          ),
          values,
        )}
      </div>
    );
  }

  return <DisabledTextField value={displayedValue} />;
};
export const disabled = DisabledUseCodeField;

export const IssuedUseCodeField = ({ value }) => {
  const use = value?.use;
  if (!use) return <DisabledTextField />;

  return (
    <>
      <DisabledTextField value={use.full_name} />
      <DisabledTextField value={use.description} />
      {use.reference_url && (
        <ExternalLink href={use.reference_url}>Specific Use Standards</ExternalLink>
      )}
    </>
  );
};
export const issued = IssuedUseCodeField;
