/* eslint-disable import/no-import-module-exports */

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import * as actions from "actions";
import { middleware as routerMiddleware } from "components/Router";
import rootReducer from "reducers";
import rootSaga from "sagas";
import EventsToState from "services/EventsToState";
import zoningCache from "services/ZoningCache";
import { reportError } from "services/errorReporter";
import tracker from "services/tracking";
import isHeadless from "utils/isHeadless";

const configureStore = ({ initialState }) => {
  const middleware = [];
  const loggerConfig = {
    diff: true,
    collapsed: (getState, action, logEntry) => !logEntry.error,
  };
  const logger = createLogger(loggerConfig);

  const sagaMiddleware = createSagaMiddleware({
    onError: reportError,
  });

  middleware.push(sagaMiddleware);
  middleware.push(routerMiddleware);
  if (!isHeadless()) middleware.push(logger);
  middleware.push(tracker);

  const enhancer = composeWithDevTools({
    name: "OpenCounter",
    actionCreators: actions,
    trace: true,
    actionsDenyList: ["@@redux-form/"],
  })(applyMiddleware(...middleware));

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept("../reducers", () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { default: nextRootReducer } = require("../reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  EventsToState.configure(store);
  zoningCache.configure(store);
  return store;
};

export default configureStore;
