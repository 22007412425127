import { datadogRum } from "@datadog/browser-rum";
import * as R from "ramda";

import { isProductionBuild } from "env";
import { getGuideType, selectCurrentProject } from "reducers/projects";
import { selectFullName, selectTenant, selectTenantSlug } from "reducers/tenant";
import { selectLocale } from "reducers/ui";
import {
  getCreatedAt,
  getEmail,
  getFirstName,
  getHasAdminAccess,
  getIsGuest,
  getLastName,
  getName,
  getPhoneNumber,
  getUserHash,
  isOpenCounterStaff,
  selectCurrentUser,
} from "selectors/users";
import { reportError } from "services/errorReporter";
import { createAnalyticsStub } from "utils/analyticsStub";
import { isAdminURL } from "utils/urls";

const analytics = isProductionBuild && window.analytics ? window.analytics : createAnalyticsStub();

const globalAttrs = (state) => {
  const user = selectCurrentUser(state);
  const project = selectCurrentProject(state);

  return {
    portal: getGuideType(project),
    tenant: selectTenantSlug(state),
    locale: selectLocale(state),
    userID: R.prop("id", user),
    guest: getIsGuest(user),
    hasAnyAdminAccess: getHasAdminAccess(user),
  };
};

const globalOptions = (state) => {
  const adminPage = isAdminURL(window.location);
  const user = selectCurrentUser(state);

  return {
    integrations: {
      Intercom: adminPage && {
        user_hash: getUserHash(user),
      },
      Wootric: adminPage,
    },
  };
};

const pageWithGlobals = (state, name, props, options) => {
  try {
    return analytics._page(
      name,
      R.mergeRight(globalAttrs(state), props),
      R.mergeRight(globalOptions(state), options),
    );
  } catch (error) {
    reportError(error, { context: { component: "analytics" } });
  }
  return undefined;
};

const trackWithGlobals = (state, event, props, options) => {
  try {
    return analytics._track(
      event,
      R.mergeRight(globalAttrs(state), props),
      R.mergeRight(globalOptions(state), options),
    );
  } catch (error) {
    reportError(error, { context: { component: "analytics" } });
  }
  return undefined;
};

const identifyWithGlobals = (state, props, options) => {
  try {
    const user = selectCurrentUser(state);
    if (getIsGuest(user)) return;

    const tenant = selectTenant(state);
    const userId = R.prop("id", user);
    const globals = globalAttrs(state);
    const { guest, hasAnyAdminAccess } = globals;
    const email = getEmail(user);
    const name = getName(user);
    const openCounterStaff = isOpenCounterStaff(user);
    const created_at = getCreatedAt(user);

    datadogRum.setUser({
      id: userId,
      email,
      name,
      created_at,
      opencounter_staff: openCounterStaff,
      guest,
      admin_access: hasAnyAdminAccess,
    });

    analytics._identify(
      userId,
      {
        ...globalAttrs(state),
        email,
        firstName: getFirstName(user),
        lastName: getLastName(user),
        phoneNumber: getPhoneNumber(user),
        isOpencounterStaff: openCounterStaff,
        created_at,
        company: {
          id: R.prop("salesforce_id", tenant) || R.prop("id", tenant),
          name: selectFullName(state),
        },
        ...props,
      },

      R.mergeRight(globalOptions(state), options),
    );
  } catch (error) {
    reportError(error, { context: { component: "analytics" } });
  }
};

export const configAnalytics = (store) => {
  analytics._track = analytics.track;
  analytics.track = (event, props = {}, options = {}) =>
    trackWithGlobals(store.getState(), event, props, options);

  analytics._page = analytics.page;
  analytics.page = (name, props = {}, options = {}) =>
    pageWithGlobals(store.getState(), name, props, options);

  analytics._identify = analytics.identify;
  analytics.identify = (_, props = {}, options = {}) =>
    identifyWithGlobals(store.getState(), props, options);
};

export default analytics;
