import { curry } from "ramda";

/**
 * Paths in our appication should be absolute (since we reuse components in different contexts and emails)
 * and should be created using the reusable functions which are located here.
 *
 * usage:
 *  import routes from "utils/routes";
 *  routes.admin.project(id);
 *  routes.requirementApplication(projectID, raID);
 */
const routes = {
  application: (pID, rID) => `/projects/${pID}/application/${rID}`,
  projects: () => "/projects",
  projectHandoff: (id) => `/projects/${id}/apply`,
  projectMessages: (id) => `/projects/${id}/apply/messages`,
  projectAttachments: (id) => `/projects/${id}/apply/attachments`,
  requirementApplication: curry((pID, raID) => `/projects/${pID}/application/${raID}`),

  admin: {
    project: (id, page = undefined) => {
      const path = `/admin/projects/${id}`;
      return page ? `${path}/${page}` : path;
    },
    application: (projectID, applicationID) =>
      `/admin/projects/${projectID}/applications/${applicationID}`,
    editProjectRequirements: (projectID) => {
      const path = `/admin/projects/${projectID}/changesets/edit-requirements`;
      return path;
    },
    editProjectScoping: (projectID) => {
      const path = `/admin/projects/${projectID}/changesets/edit-scoping`;
      return path;
    },
    editApplication: (projectID, requirementID) =>
      `/admin/projects/${projectID}/changesets/edit-application/${requirementID}`,
    editApplicationFees: (projectID, requirementID) =>
      `/admin/projects/${projectID}/changesets/edit-fees/${requirementID}`,
    editApplicationAdminFields: (projectID, requirementID) =>
      `/admin/projects/${projectID}/changesets/edit-admin/${requirementID}`,
    applicationNoLongerReequired: (projectID, requirementID) =>
      `/admin/projects/${projectID}/changesets/no-longer-required/${requirementID}`,
    requirementApplication: (pID, raID) => `/admin/projects/${pID}/applications/${raID}`,
  },
  config: {
    editTenant: (t) => `${t.subdomain_url}/config/tenants/_/edit`,
  },
};

export default routes;
