import { Changeset } from "types/adminEntities";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/admin/changesets",
  baseKey: ["changesets"],
  dataKey: "changeset",
});

export const useChangeset = (id: number, opts = {}) =>
  operation.useRead<Changeset>(id, {
    ...opts,
    basePath: "/api/changesets",
  });

export const useProjectChangesets = (projectID: number, opts = {}) =>
  operation.useRead<Changeset[]>(`?project_id=${projectID}`, {
    ...opts,
    key: operation.queryKey(["forProject", projectID]),
  });

export const useUpdateChangeset = (id: number, opts = {}) =>
  operation.useUpdate<Changeset>(id, opts);

export const useRevertChangeset = (id: number, opts = {}) =>
  operation.useUpdate<Changeset>([id, "revert"], opts);
