/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";

const useStopPropagation = (fn = null, deps = []) =>
  useCallback(
    fn instanceof Function
      ? (e) => {
          e.stopPropagation();
          return fn(e);
        }
      : null,
    [fn, ...deps],
  );

export default useStopPropagation;
