import * as R from "ramda";
import { createSelector } from "reselect";

import * as actions from "actions";
import * as AnswerContext from "reducers/answerContexts";
import { getFeesWithCosts, selectFees, selectRequirementFeeTypes } from "reducers/fees";
import {
  isUseCodeCondition,
  rejectCalculatedFields,
  selectFieldIDs,
  selectFieldsByID,
  selectFieldsByType,
  selectFilteredFieldIDs,
  selectPrimaryFieldByType,
} from "reducers/fields";
import { selectGuideByID, selectGuideByType } from "reducers/guides";
import * as Page from "reducers/pages";
import * as Requirement from "reducers/requirements";
import { selectFormattedText } from "selectors/translations";
import { getFullAddress, getLatitude, getLongitude } from "utils/address";
import { formatTwoDecimalPlaces } from "utils/format";
import { EMPTY_ARRAY, EMPTY_OBJECT, compact, isBlank } from "utils/func";
import * as LocationUtils from "utils/location";
import { getStartAddress } from "utils/route";
import { parseTime } from "utils/time";
import {
  getBaseZone,
  getEffectiveZone,
  getParcelIDs,
  getPermission,
  getZonePermissionCategory,
  getZoneZoningDistrict,
} from "utils/zoning";

export default (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case actions.ADD_PROJECT:
      return R.assoc(pl.id, { ...pl, errors: {} }, state);
    // TODO: this could be extracted to answerContext reducer & shared w/ changesets
    case actions.UPDATE_CONTEXT: {
      if (R.has(pl.id, state) && state[pl.id].updated_at === pl.updated_at) return state;

      let nextState = updateContextInState(pl, state);
      if (R.has("derived_child", pl)) nextState = updateContextInState(pl.derived_child, nextState);

      return nextState;
    }
    case actions.UPDATE_REQUIREMENT_APPLICATION: {
      if (!(pl.project_id && pl.id)) return state;
      if (!state[pl.project_id]) return state;
      return R.assocPath([pl.project_id, "requirement_applications", pl.id], pl, state);
    }
    default:
      return AnswerContext.reducer(state, action);
  }
};

function updateContextInState(newContext, state) {
  const existingContext = R.propOr({}, newContext.id, state);
  const context = {
    ...newContext,
    answers: R.mergeRight(existingContext.answers, newContext.answers),
    errors: existingContext.errors || {},
  };
  return R.assoc(context.id, context, state);
}

export const selectProjects = R.prop("projects");

export const selectIsProjectLoaded = (state, id) => R.has(id, state.projects);

export const getIsZoningEnabled = R.path(["answers", "zoning_enabled"]);

// TODO
// only used in ZoningCache & analytics
export const selectCurrentProject = (state) =>
  selectProjectByID(state, selectCurrentProjectIDFromUrl());

const selectCurrentProjectIDFromUrl = (pathname = window.location.pathname) => {
  const result = /projects\/([0-9a-z-]+)\//.exec(pathname);
  const number = result && Number(R.last(result));
  return result && (Number.isNaN(number) ? R.last(result) : number);
};

export const selectProjectByID = (state, id) => R.propOr(EMPTY_OBJECT, id, state.projects);

export const getZoningFieldIDs = R.pathOr(EMPTY_ARRAY, ["user_pages", "zoning_fields", "list"]);

export const selectActiveUseCodeConditionFields = (state, project) => {
  const ids = selectFilteredFieldIDs(state, isUseCodeCondition);
  const activeFieldIds = R.intersection(getZoningFieldIDs(project), ids);
  return selectFieldsByID(state, activeFieldIds);
};

export const selectMissingFieldIDs = (state, context) => {
  const loadedFieldIDs = selectFieldIDs(state);
  const requiredFieldIDs = AnswerContext.getActiveFieldIDs(context);

  return R.difference(requiredFieldIDs, loadedFieldIDs);
};

export const selectUnansweredFieldCountForPageSlug = (state, project, pageSlug) =>
  getUnansweredCountForFields(
    project,
    AnswerContext.selectCurrentActiveFieldsForProjectSlug(state, project, pageSlug),
  );

export const selectCurrentFees = (state, project) => {
  const feeTotals = getFeeTotals(project);
  const fees = selectFees(state);
  return getFeesWithCosts(feeTotals, fees);
};

export const selectRequirementFees = (state, project) => {
  const feeTotals = getFeeTotals(project);
  const fees = selectRequirementFeeTypes(state);

  return getFeesWithCosts(feeTotals, fees);
};

export const getCurrentRequirementApplications = R.pipe(
  R.prop("requirement_applications"),
  R.values,
);

export const selectUseCode = (state, project) => {
  const useCode = selectPrimaryFieldByType(state, "use_code");
  return AnswerContext.getAnswerForField(project, useCode);
};

export const getUseCodeSearchPosition = ({ use, results }) =>
  R.findIndex(R.propEq(R.prop("slug", use), "slug"), results || []) + 1;

export const selectUseCodeSearchQuery = (state, project) => {
  const field = selectPrimaryFieldByType(state, "use_code");
  if (!field) {
    return "";
  }
  const answer = AnswerContext.getAnswerForField(project, field);
  return R.prop("query", answer);
};

export const hasAddress = (project) => {
  const value = project.answers?.address;
  return !!value?.latitude;
};

export const selectLocationDescription = (state, project) => {
  const value = project.answers?.address;
  const zoning = getZoning(project);

  let addressDescription = getFullAddress(value);
  if (isBlank(addressDescription)) {
    const parcelIDs = getParcelIDs(zoning);
    if (!isBlank(parcelIDs))
      addressDescription = selectFormattedText(state, "fields.zoning.parcel", {
        parcelID: R.head(parcelIDs),
      });
  }

  if (isBlank(addressDescription)) {
    addressDescription = selectFormattedText(state, "fields.zoning.unaddressed", {
      latitude: formatTwoDecimalPlaces(getLatitude(value)),
      longitude: formatTwoDecimalPlaces(getLongitude(value)),
    });
  }

  return addressDescription;
};
export const getZoning = (project) => project?.answers?.zoning || defaultZoning;

const defaultZoning = { zones: [], parcels: [] };
export const selectZoning = (state, project) => {
  const field = selectPrimaryFieldByType(state, "zoning");
  if (!field) return defaultZoning;

  return AnswerContext.getAnswerForField(project, field) || defaultZoning;
};

export const selectPermission = R.pipe(selectZoning, getPermission);
export const selectEffectiveZone = R.pipe(selectZoning, getEffectiveZone);
export const selectZoningPermissionCategory = R.pipe(
  selectEffectiveZone,
  getZonePermissionCategory,
  R.defaultTo(""),
);
export const selectZoningDistrict = R.pipe(selectZoning, getBaseZone, getZoneZoningDistrict);

export const selectZoningFields = (state, project) =>
  selectFieldsByID(state, getZoningFieldIDs(project));

export const getUnansweredCountForFields = (project, fields) =>
  R.reduce((c, f) => (isAnswerBlankForField(project, f) ? c + 1 : c), 0, fields);

export const selectOrderedInvalidFieldsByID = (state, project, fieldIDs) =>
  R.pipe(
    AnswerContext.getErrors,
    R.keys,
    R.map(Number),
    R.filter(R.includes(R.__, fieldIDs)),
    R.curry(selectFieldsByID)(state),
  )(project);

export const selectProjectsPageURL = () => "/projects";

export const selectIsInlinePage = (state, project) => {
  const isZoningEnabled = getIsZoningEnabled(project);
  if (!isZoningEnabled) return false;

  const requirementIDs = getRequirementIDs(project);
  const requirements = Requirement.selectRequirementsByID(state, requirementIDs);
  if (R.isEmpty(requirements)) return true;
  const permissionCategory = selectZoningPermissionCategory(state, project);
  return permissionCategory === "prohibited";
};

export const getLogEntries = R.pipe(
  R.propOr({}, "log_entries_json"),
  R.values,
  R.sortBy(R.prop("timestamp")),
  R.map(R.over(R.lensProp("timestamp"), parseTime)),
);

export const getLogEntriesByRequirementApplicationID = (project, id) =>
  R.filter(
    R.anyPass([
      R.propEq(id, "requirement_application_id"),
      R.propEq("changeset_applied", "log_type"),
    ]),
  )(getLogEntries(project));

export const selectCalculateRequirements = (state, project) => {
  if (!project) return false;

  const guide = selectGuideByID(state, project.guide_id);
  return R.propOr(false, "calculate_requirements", guide);
};

export const selectHasDocuments = (state, project) => {
  const fields = selectFieldsByType(state, "attachment") || [];
  const answers = AnswerContext.getAnswersForFieldsIndexByID(project, fields);
  return !R.all(R.isNil, R.values(answers));
};

const isScopingSection = R.flip(R.includes)(["scoping", "fee_scoping"]);
const forceRequired = R.map(R.assoc("required", true));

const getProjectRequirementSectionList = (project, requirement) =>
  R.path(["user_pages", R.prop("key", requirement)], project);

export const selectProjectRequirementSections = (
  state,
  project,
  requirement,
  {
    withFeeFields = false,
    withScopingFields = false,
    fieldFilter = R.identity,
    sectionFilter = R.identity,
  } = {},
) => {
  let usedFields = [];
  const requirementSectionList = getProjectRequirementSectionList(project, requirement);
  if (!requirementSectionList) {
    return [];
  }

  return R.pipe(
    R.propOr([], "order"),
    sectionFilter,
    R.reject(
      (sectionID) =>
        (sectionID === "scoping" && !withScopingFields) ||
        (sectionID === "fee_scoping" && !withFeeFields),
    ),
    R.map((sectionID) => {
      const section = Requirement.getSectionByID(requirement, sectionID);
      const fieldIDs = R.compose(
        R.without(usedFields),
        R.path(["sections", sectionID]),
      )(requirementSectionList);
      usedFields = R.concat(usedFields, fieldIDs);
      const allRequired = isScopingSection(sectionID) ? forceRequired : R.identity;
      const fields = R.pipe(
        selectFieldsByID,
        rejectCalculatedFields,
        fieldFilter,
        allRequired,
      )(state, fieldIDs);
      if (R.isEmpty(fields)) {
        return null;
      }
      return R.assoc("fields", fields, section);
    }),
    R.reject(R.isNil),
  )(requirementSectionList);
};

export const selectProjectRequirementFeeFields = (state, project, requirement) =>
  R.pathOr(
    [],
    [0, "fields"],
    selectProjectRequirementSections(state, project, requirement, {
      withFeeFields: true,
      withScopingFields: false,
      sectionFilter: R.filter(R.flip(R.includes)(["fee_scoping"])),
    }),
  );

export const getProjectRequirementFieldIDs = (project, requirement) =>
  R.pipe(
    R.propOr({}, "sections"),
    R.values,
    R.flatten,
    R.uniq,
  )(getProjectRequirementSectionList(project, requirement));

export const getProjectRequirementFields = (
  project,
  requirement,
  fieldSelector,
  {
    withFeeFields = false,
    withScopingFields = false,
    fieldFilter = R.identity,
    sectionFilter = R.identity,
  } = {},
) => {
  let usedFields = [];
  const requirementFieldData = R.path(["user_pages", R.prop("key", requirement)], project);
  if (!requirementFieldData) {
    return [];
  }

  return R.pipe(
    R.prop("order"),
    sectionFilter,
    R.reject(
      (sectionID) =>
        (sectionID === "scoping" && !withScopingFields) ||
        (sectionID === "fee_scoping" && !withFeeFields),
    ),
    R.map((sectionID) => {
      const section = Requirement.getSectionByID(requirement, sectionID);
      const fieldIDs = R.without(usedFields, R.path(["sections", sectionID], requirementFieldData));
      usedFields = R.concat(usedFields, fieldIDs);
      const allRequired = isScopingSection(sectionID) ? forceRequired : R.identity;
      const allFields = fieldSelector(fieldIDs);

      const fields = R.pipe(rejectCalculatedFields, fieldFilter, allRequired)(allFields);

      if (R.isEmpty(fields)) {
        return null;
      }
      return R.assoc("fields", fields, section);
    }),
    R.reject(R.isNil),
  )(requirementFieldData);
};
export const selectProjectScopingFields = (state, project, { fieldFilter }) => {
  const opts = {
    fieldFilter,
    withScopingFields: true,
    withFeeFields: true,
    sectionFilter: R.filter(R.flip(R.includes)(["scoping", "fee_scoping"])),
  };
  const requirements = Requirement.selectRequirements(state);
  const requirementSections = R.chain((requirements) =>
    selectProjectRequirementSections(state, project, requirements, opts),
  )(requirements);
  const scopingSections = R.reduce(
    (acc, section) => {
      if (acc[section.key]) {
        return R.over(
          R.lensPath([section.key, "fields"]),
          R.pipe(R.concat(section.fields), R.uniq),
          acc,
        );
      }
      return R.assoc(section.key, section, acc);
    },
    {},
    requirementSections,
  );

  return R.reject(R.isNil)([scopingSections.scoping, scopingSections.fee_scoping]);
};

export const getRequirementRequirementApplicationID = R.prop("requirement_id");

export const getRequirementApplicationByRequirementID = (project, requirementID) =>
  R.pipe(
    R.prop("requirement_applications"),
    R.values,
    R.find(R.propEq(Number(requirementID), "requirement_id")),
  )(project);

export const getApplicant = R.prop("user");
export const getRequirementApplications = createSelector(
  R.prop("requirement_applications"),
  R.values,
);
export const getRequirementApplication = (project, id) =>
  R.path(["requirement_applications", id], project);
export const getLockedFieldIDs = R.propOr([], "locked_field_ids");
export const getStatus = R.prop("status");
export const isInProgress = R.propEq("In-Progress", "status");
export const isProjectLoaded = R.has("answers");
export const getAddress = R.prop("business_address");
export const getName = R.path(["answers", "project_name"]);
export const getUseCodeName = R.prop("use_code_name");
export const getGuideType = R.path(["guide", "guide_type"]);
export const getTotalFees = R.pipe(R.prop("calculated_fee_totals"), R.values, R.sum);
export const getRequirementIDs = R.propOr(EMPTY_ARRAY, "requirement_ids");
export const getEventType = R.path(["answers", "event_type"]);
export const getEventLocations = R.pathOr(EMPTY_ARRAY, ["answers", "event_locations"]);
export const getEventRoutes = R.pathOr(EMPTY_ARRAY, ["answers", "event_routes"]);

export const getEventTime = (project, type) =>
  R.pipe(
    R.pathOr([], ["answers", "event_times"]),
    R.pluck(type),
    R.map(parseTime),
    R.reduce(R.min, Infinity),
    R.ifElse(R.equals(Infinity), R.always(undefined), R.identity),
  )(project);

export const getEventStart = (project) => getEventTime(project, "start_time");

export const getLegacyEventPrimaryAddress = (project) => {
  const locations = getEventLocations(project);
  if (!isBlank(locations)) return R.pipe(R.head, LocationUtils.getAddress)(locations);

  const routes = getEventRoutes(project);
  if (!isBlank(routes)) return R.pipe(R.head, getStartAddress)(routes);

  return null;
};

export const getFeeTotals = R.propOr({}, "calculated_fee_totals");

// TODO: refactor guide_type checks to use these
export const isZoning = R.pipe(getGuideType, R.equals("zoning"));
export const isEvent = R.pipe(getGuideType, R.equals("special_events"));
export const isDirect = R.pipe(getGuideType, R.equals("direct_application"));

export const isAnswerBlankForField = (project, field) => {
  const a = AnswerContext.getAnswerForField(project, field);

  if (Array.isArray(a)) return R.pipe(R.flatten, compact, R.isEmpty)(a);

  if (a instanceof Object) return R.pipe(R.values, compact, R.flatten, R.isEmpty)(a);

  if (typeof a === "string" || a instanceof String) return R.isEmpty(a);

  return R.isNil(a);
};
export const filterUnansweredFields = R.curry((project, fields) =>
  R.reject((f) => isAnswerBlankForField(project, f), fields),
);

export const areAnyFieldsAnswered = (project, fields) =>
  R.any((f) => !isAnswerBlankForField(project, f), fields);

const getPageRelativeTo = (state, project, pageSlug, direction) => {
  const activeGuidePages = AnswerContext.getGuidePages(project);
  const pageSlugs = R.propOr([], "order")(activeGuidePages);
  const currentPageIndex = pageSlugs.indexOf(pageSlug);
  let adjacentIndex = currentPageIndex + direction;

  if (adjacentIndex < 0) return { slug: "" }; // home
  if (adjacentIndex >= pageSlugs.length) adjacentIndex = pageSlugs.length - 1;

  const adjacentPageSlug = pageSlugs[adjacentIndex];
  const guide = selectGuideByType(state, getGuideType(project));

  return Page.selectPageBySlug(state, guide, adjacentPageSlug);
};

export const selectFirstPage = (state, project) => {
  const pageSlug = R.path(["user_pages", "guide", "order", 0], project);
  const guide = selectGuideByType(state, getGuideType(project));

  return Page.selectPageBySlug(state, guide, pageSlug);
};
export const selectNextPage = (state, project, pageSlug) =>
  getPageRelativeTo(state, project, pageSlug, 1);
export const selectPreviousPage = (state, project, pageSlug) =>
  getPageRelativeTo(state, project, pageSlug, -1);

export const getRenewalDiff = R.propOr({}, "renewal_diff");
