import * as R from "ramda";

import {
  DepartmentSummary,
  EntitySummary,
  FeeSummary,
  FieldSummary,
  GuideSummary,
  RequirementSummary,
} from "types/configEntities";
import { indexByID } from "utils/func";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/entities",
  baseKey: ["entities"],
});

const filterByType = (type: string) => R.filter(R.propEq(type, "type"));

export const useEntitiesList = <T = EntitySummary[]>(opts = {}) => operation.useRead<T>(null, opts);

export const useEntitiesListForType = <T = EntitySummary[]>(
  type: string,
  { select = R.identity, ...opts } = {},
) =>
  useEntitiesList<T>({
    select: R.pipe(filterByType(type), select),
    ...opts,
  });

export const useFeesList = (opts = {}) => useEntitiesListForType<FeeSummary[]>("Fee", opts);
export const useFieldsList = (opts = {}) => useEntitiesListForType<FieldSummary[]>("Field", opts);
export const useRootFieldsList = ({ select = R.identity, ...opts } = {}) =>
  useFieldsList({
    ...opts,
    select: R.compose(select, R.filter(R.propSatisfies(R.isNil, "parent_id"))),
  });
export const useRequirementsList = (opts = {}) =>
  useEntitiesListForType<RequirementSummary[]>("Requirement", opts);
export const useDepartmentsList = (opts = {}) =>
  useEntitiesListForType<DepartmentSummary[]>("Department", opts);
export const useGuidesList = (opts = {}) => useEntitiesListForType<GuideSummary[]>("Guide", opts);

interface Entity {
  identifier: string;
}

const findList: (idents: string[]) => (entities: Entity[]) => any[] = (idents) =>
  R.filter((entity: Entity) => R.includes(entity.identifier, idents));

const findRecord = (ident: string) => R.find((entity: Entity) => entity.identifier === ident);

const findRecordByID = (id: number) => R.compose(R.prop(id), indexByID);

export const useFindEntities = (idents: string[], opts = {}) =>
  useEntitiesList({ ...opts, select: findList(idents) });

export const useEntity = (identifier: string, opts = {}) =>
  useEntitiesList<EntitySummary | null>({
    ...opts,
    select: findRecord(identifier),
  });

export const useEntityById = (type: string, id: number, opts = {}) =>
  useEntitiesList<EntitySummary | null>({
    ...opts,
    select: R.pipe(filterByType(type), findRecordByID(id)),
  });
