import React, { useState } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import Markdown from "components/Markdown";

import Popover from "./Popover";
import styles from "./Tooltip.scss";

const useHover = ({ debounce: debounceInterval = 250 } = {}) => {
  const [isHovered, setOnHover] = useState(false);
  const debouncedHover = debounceInterval ? debounce(setOnHover, debounceInterval) : setOnHover;

  return {
    isHovered,
    setOnHover: debouncedHover,
    handlers: {
      onMouseEnter() {
        debouncedHover(true);
      },
      onMouseLeave() {
        debouncedHover(false);
      },
    },
  };
};
const Tooltip = ({
  message,
  text,
  icon = "question-circle",
  direction,
  className,
  disabled = false,
  children = undefined,
  testID,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const { isHovered, handlers: hoverHandlers } = useHover();
  if (disabled) return children;

  return (
    <span
      role="tooltip"
      className={className}
      data-testid={testID}
      ref={setReferenceElement}
      {...hoverHandlers}
    >
      {icon && <Icon icon={icon} />}
      {text && <span>{text}</span>}
      {children}
      {isHovered && (
        <Popover
          referenceElement={referenceElement}
          className={styles.message}
          placement={direction}
        >
          <Markdown source={message} className={styles.markdown} />
        </Popover>
      )}
    </span>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
  message: PropTypes.string.isRequired,
  direction: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  testID: PropTypes.string,
};

export default Tooltip;
