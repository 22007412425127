import * as R from "ramda";

import { SET_DEPARTMENTS } from "actions";

export default (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case SET_DEPARTMENTS:
      return pl;
    default:
      return state;
  }
};

const getDepartments = R.prop("departments");

export const getName = R.prop("name");
export const getSlug = R.prop("slug");
export const getUserCount = R.prop("user_count");
export const getID = R.prop("id");
export const getPhone = R.prop("phone");
export const getAddress = R.prop("address");
export const getURL = R.prop("url");

export const selectDepartments = R.pipe(getDepartments, R.values, R.sortBy(getName));

export const selectDepartmentForRequirement = (state, { department_id }) =>
  selectDepartmentByID(state, department_id);

export const selectDepartmentsByID = R.curry((state, IDs) =>
  R.compose(R.sortBy(getName), R.values, R.pickAll(IDs || []), getDepartments)(state),
);

export const selectDepartmentByID = (state, id) => R.path(["departments", id], state);
