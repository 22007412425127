import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Markdown from "components/Markdown";
import RequirementHandoffCard from "components/projects/handoff/RequirementHandoffCard";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectDepartments } from "reducers/departments";
import { getHandoffSections, selectGuideByID } from "reducers/guides";
import {
  getCurrentRequirementApplications,
  getRequirementRequirementApplicationID,
} from "reducers/projects";
import { selectRequirementsByID } from "reducers/requirements";
import { isBlank, mapIndexed } from "utils/func";
import routes from "utils/routes";
import {
  departmentPropType,
  feePropType,
  requirementApplicationPropType,
  requirementPropType,
} from "utils/sharedPropTypes";

import styles from "./RequirementsBySection.scss";

const Section = ({
  index,
  section,
  departments,
  requirements,
  requirementApplications,
  projectID,
}) => {
  const sectionTitle = !isBlank(section.title) ? (
    <h1 className={styles.sectionTitle}>{section.title}</h1>
  ) : null;

  return (
    <div className={styles.sectionContainer} data-section={index}>
      <div className={styles.indexContainer}>
        <div className={styles.index}>{index + 1}</div>
      </div>
      <div className={styles.content}>
        {sectionTitle}
        <Markdown source={section.description} />

        <div className={styles.requirementsContainer}>
          {R.map((ra) => {
            const requirement = R.find(R.propEq(ra.requirement_id, "id"), requirements);
            const department = R.find(R.propEq(requirement.department_id, "id"), departments);

            return (
              <RequirementHandoffCard
                key={`requirement[${requirement.id}]`}
                requirement={requirement}
                requirementApplication={ra}
                href={routes.requirementApplication(projectID, requirement.id)}
                department={department}
              />
            );
          }, requirementApplications)}
        </div>
      </div>
    </div>
  );
};
Section.propTypes = {
  requirements: PropTypes.arrayOf(requirementPropType).isRequired,
  departments: PropTypes.arrayOf(departmentPropType).isRequired,
  index: PropTypes.number.isRequired,
  section: PropTypes.shape({ description: PropTypes.string, title: PropTypes.string }).isRequired,
  requirementApplications: PropTypes.arrayOf(requirementApplicationPropType).isRequired,
  fees: PropTypes.shape({ id: feePropType }).isRequired,
};

const RequirementsBySection = () => {
  const { record: project } = useAnswerContext();
  const requirementApplications = getCurrentRequirementApplications(project);
  const requirementIDs = R.map(getRequirementRequirementApplicationID, requirementApplications);
  const requirements = useSelector((state) => selectRequirementsByID(state, requirementIDs));
  const guide = useSelector((state) => selectGuideByID(state, project.guide_id));
  const departments = useSelector(selectDepartments);

  const sections = R.compose(
    R.reject(
      ({ requirementApplications, section: { requirement_ids, description } }) =>
        R.isEmpty(requirementApplications) &&
        (!R.isEmpty(requirement_ids) || R.isEmpty(description)),
    ),
    R.map((section) => {
      const { requirement_ids } = section;
      const requirementApplicationsForSection = R.filter(
        R.propSatisfies(R.includes(R.__, requirement_ids), "requirement_id"),
        requirementApplications,
      );
      return {
        section,
        requirementApplications: requirementApplicationsForSection,
      };
    }),
    getHandoffSections,
  )(guide);

  return (
    <div>
      <div className={styles.sections}>
        {mapIndexed(({ section, requirementApplications }, index) => (
          <Section
            key={`section[${index}]`}
            section={section}
            index={index}
            departments={departments}
            requirements={requirements}
            requirementApplications={requirementApplications}
            projectID={project.id}
          />
        ))(sections)}
      </div>
    </div>
  );
};
export default RequirementsBySection;
