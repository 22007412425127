import * as R from "ramda";

import {
  CREATE_PROJECT,
  DOWNLOAD_ERROR,
  DOWNLOAD_EXPORT,
  DOWNLOAD_ISSUED_REQUIREMENT_PDF,
  DOWNLOAD_PDF,
  DOWNLOAD_REQUIREMENT_APPLICATION_PDF,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_TRANSACTIONS,
  FETCH_INITIAL_STATE,
  INITIALIZE_STATE,
  LOCK_TO_GUIDE,
  SET_ADMIN_BAR_EXPANDED,
  SET_DEVICE_TYPE,
  SET_LOCALE,
} from "actions";

const initialState = {
  fixedGuideID: null,
  searchUseCodePristine: true,
  visitedPages: [],
  locale: "en",
  isDownloadInProgress: false,
  adminBar: { expanded: false },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_STATE:
      return R.assoc("initialStateLoaded", false, state);
    case INITIALIZE_STATE:
      return R.assoc("initialStateLoaded", true, state);
    case CREATE_PROJECT:
      return R.mergeRight(
        initialState,
        R.pick(["deviceType", "fixedGuideID", "locale", "initialStateLoaded"], state),
      );
    case LOCK_TO_GUIDE:
      return R.assoc("fixedGuideID", action.payload, state);
    case SET_DEVICE_TYPE:
      return R.assoc("deviceType", action.payload, state);
    case DOWNLOAD_PDF:
    case DOWNLOAD_REQUIREMENT_APPLICATION_PDF:
    case DOWNLOAD_ISSUED_REQUIREMENT_PDF:
    case DOWNLOAD_EXPORT:
    case DOWNLOAD_TRANSACTIONS:
      return R.assoc("isDownloadInProgress", true, state);
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_ERROR:
      return R.assoc("isDownloadInProgress", false, state);
    case SET_LOCALE:
      return R.assoc("locale", action.payload, state);
    case SET_ADMIN_BAR_EXPANDED:
      return R.assocPath(["adminBar", "expanded"], action.payload, state);
    default:
      return state;
  }
};

export const selectInitialStateLoaded = R.path(["ui", "initialStateLoaded"]);

export const selectFixedGuideID = R.path(["ui", "fixedGuideID"]);

export const selectCurrentPageSlug = () => {
  const result = /projects\/[0-9]+\/guide\/([^/]+)/.exec(window.location.pathname);
  return result && R.last(result);
};

export const selectDeviceType = R.path(["ui", "deviceType"]);

export const selectIsMobile = (state) => selectDeviceType(state) === "mobile";

export const selectDownloadInProgress = R.pathOr(false, ["ui", "isDownloadInProgress"]);

export const selectLocale = R.pathOr("en", ["ui", "locale"]);

export const selectIsAdminBarExpanded = R.path(["ui", "adminBar", "expanded"]);
