import snakeCase from "lodash.snakecase";
import * as R from "ramda";
import { useQueries, useQuery } from "react-query";

import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useVersionedClientRef } from "contexts/versionedClient";
import { getUseCodes } from "reducers/tenant";

// import zoningApi from "services/zoningApi";

interface SluggedRecord {
  slug: string;
}
const slugEq = (s: string) => (r: SluggedRecord) => R.propEq(s, "slug", r);

export const useUseCodesForQuery = (query, queryOpts = {}) => {
  const tenant = useCurrentTenantInfo();
  const client = useVersionedClientRef();

  return useQuery({
    queryKey: ["zoning", "use_codes", query],
    queryFn() {
      const slugMatch = /^use_code:([a-zA-Z0-9_-]+)$/.exec(query);
      if (slugMatch) {
        const slug: string = snakeCase(slugMatch[1]).replaceAll("_", "-");
        return R.filter(slugEq(slug), getUseCodes(tenant) as SluggedRecord[]);
      }
      return getUseCodesForQuery({ query, client });
    },
    ...queryOpts,
  });
};

export const useUseCodesKeywordsQuery = (keywords: string[], opts = {}) => {
  const client = useVersionedClientRef();

  return useQueries(
    keywords.map((keyword) => ({
      queryKey: ["zoning", "use_codes", "keywords", keyword], // key differs from useUseCodesForQuery due to different return format
      async queryFn() {
        const useCodes = await getUseCodesForQuery({ query: keyword, client });
        return { text: keyword, results: useCodes };
      },
      ...opts,
    })),
  );
};

async function getUseCodesForQuery({ query, client }) {
  const { data } = await client.current.get("/api/zoning/uses", {
    params: {
      "filter[query_string]": query,
    },
  });
  return R.compose(R.map(R.prop("attributes")), R.prop("data"))(data);
}
