import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useSelector } from "react-redux";

import { ButtonDiv } from "components/accessibility/Div";
import Text from "containers/Text";
import useCurrentGuide from "hooks/useCurrentGuide";
import * as Departments from "reducers/departments";
import { getRequirementIDs } from "reducers/guides";
import {
  filterRequirementsByDepartmentID,
  getDepartmentID,
  getDescription,
  getName,
  getSlug,
  selectRequirements,
  selectRequirementsByID,
} from "reducers/requirements";
import { indexByID } from "utils/func";
import { departmentPropType, requirementPropType } from "utils/sharedPropTypes";

import { CheckboxFieldComponent } from "./CheckboxField";
import styles from "./RequirementCatalogField.scss";
import { immediateValue } from "./behaviors";

const Requirement = ({ requirement, value, onChange, descriptionId }) => {
  const labelId = `checkbox-label-${getSlug(requirement)}`;
  return (
    <ButtonDiv
      className={styles.requirement}
      data-requirement-slug={requirement.slug}
      data-requirement-id={requirement.id}
      onClick={(e) => {
        const requirementID = e.currentTarget.dataset.requirementId;
        onChange(Number(requirementID));
        e.preventDefault();
      }}
    >
      <div className={styles.checkboxContainer}>
        <CheckboxFieldComponent
          labelId={labelId}
          descriptionId={descriptionId}
          value={R.includes(requirement.id, value)}
          onChange={R.T}
          name={getName(requirement)}
        />
      </div>

      <div className={styles.requirementContainer}>
        <div className={styles.name}>{getName(requirement)}</div>
        {getDescription(requirement)}
      </div>
    </ButtonDiv>
  );
};

Requirement.propTypes = {
  requirement: requirementPropType.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  descriptionId: PropTypes.string,
};

const Department = ({ onChange, department, requirements, descriptionId, value }) => {
  const requirementsForDepartment = R.pipe(
    filterRequirementsByDepartmentID(R.prop("id", department)),
    R.sortBy(R.prop("name")),
  )(requirements);

  return (
    <div className={styles.departmentContainer}>
      <div className={styles.departmentHeader} data-department={Departments.getName(department)}>
        <div className={styles.departmentName}>
          {Departments.getName(department)} ({requirementsForDepartment.length})
        </div>
      </div>

      <div className={styles.requirementsContainer}>
        {R.map(
          (requirement) => (
            <Requirement
              requirement={requirement}
              value={value}
              onChange={onChange}
              key={getName(requirement)}
              descriptionId={descriptionId}
            />
          ),
          requirementsForDepartment,
        )}
      </div>
    </div>
  );
};
Department.propTypes = {
  department: departmentPropType.isRequired,
  descriptionId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  requirements: PropTypes.arrayOf(requirementPropType).isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
};

const RequirementCatalogField = ({ labelId: descriptionId, value, onChange }) => {
  const guide = useCurrentGuide();
  const guideRequirementIDs = getRequirementIDs(guide);
  const requirements = useSelector((state) => {
    if (R.isEmpty(guideRequirementIDs)) {
      return selectRequirements(state);
    }
    return selectRequirementsByID(state, guideRequirementIDs);
  });
  const departmentsById = indexByID(useSelector(Departments.selectDepartments));

  const departments = R.pipe(
    R.map(getDepartmentID),
    R.reject(R.isNil),
    R.map(R.prop(R.__, departmentsById)),
    R.uniqBy(R.prop("id")),
    R.sortBy(R.prop("id")),
  )(requirements);
  const presentValue = value || [];
  const updateValue = (code) => {
    const ix = R.findIndex(R.equals(code))(presentValue);
    if (ix === -1) {
      onChange([...presentValue, code]);
    } else {
      onChange(R.remove(ix, 1, presentValue));
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.subheader}>Select a department and requirement(s):</h2>
      {R.map(
        (department) => (
          <Department
            key={department.id}
            department={department}
            requirements={requirements}
            value={presentValue}
            onChange={updateValue}
            descriptionId={descriptionId}
          />
        ),
        departments,
      )}
      <div className={styles.seperator} />
    </div>
  );
};
RequirementCatalogField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  labelId: PropTypes.string,
};

const DisabledRequirementCatalogField = ({ value }) => (
  <div className={styles.disabled}>
    <ul className={styles.list}>
      {R.map(
        (requirementID) => (
          <li key={requirementID}>{requirementID}</li>
        ),
        value || [],
      )}
    </ul>
    {R.isEmpty(value) && (
      <span className={styles.unanswered}>
        <Text t="forms.not_provided" />
      </span>
    )}
  </div>
);
DisabledRequirementCatalogField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
};

export const disabled = DisabledRequirementCatalogField;

export default immediateValue(RequirementCatalogField);
