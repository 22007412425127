import { Tenant, TenantRow } from "types/configEntities";

import resourceOperations from "../resourceOperations";

const operation = resourceOperations({
  basePath: "/api/config/tenants",
  baseKey: ["config", "tenants"],
  dataKey: "tenant",
});

type FriendlyId = string | number;

export const useTenant = (id: FriendlyId, opts = {}) => {
  if (!id) throw new Error("ID is required");
  return operation.useRead<Tenant>(id, opts);
};

export const useCurrentTenant = (opts = {}) => useTenant("_", opts);

export const useTenantsIndex = (opts = {}) => operation.useRead<TenantRow[]>(null, opts);

export const useCreateTenant = (opts = {}) => operation.useCreate<Tenant>(null, opts);

export const useUpdateTenant = (id: number, opts = {}) => operation.useUpdate<Tenant>(id, opts);

export const useDestroyTenant = (opts = {}) => operation.useDestroy(opts);
