import * as R from "ramda";

import { SET_VERSIONS } from "actions";

export default (state = {}, action) => {
  const pl = action.payload;
  switch (action.type) {
    case SET_VERSIONS:
      return R.mergeDeepLeft(pl, state);
    default:
      return state;
  }
};

export const getInitialStateURL = R.prop("initial_state_url");
export const getIsCurrent = R.propEq(true, "current");
export const getIsHead = R.propEq(true, "head?");
export const getIsCommitable = R.prop("commitable");

export const selectVersions = R.prop("versions");
export const selectVersion = (state, number) =>
  R.compose(
    number === "current" ? R.compose(R.find(getIsCurrent), R.values) : R.prop(number),
    selectVersions,
  )(state);

export const selectHeadVersion = R.compose(
  R.prop("number"),
  R.find(getIsHead),
  R.values,
  selectVersions,
);

export const selectCurrentVersion = R.compose(
  R.prop("number"),
  R.find(getIsCurrent),
  R.values,
  selectVersions,
);
export const selectInitialStateURLByVersionNumber = R.compose(getInitialStateURL, selectVersion);
