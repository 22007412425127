import * as R from "ramda";

import { humanize } from "utils/format";

interface Option {
  label: string;
  value: any;
}
type OptionsFromString = (a: string[]) => Option[];

export const listToOptions: OptionsFromString = R.map((item) => ({ label: item, value: item }));
export const listToHumanizedOptions: OptionsFromString = R.map((item) => ({
  label: humanize(item),
  value: item,
}));
export const recordsToOptions: (r: Record<string, any>[]) => Option[] = R.curry(
  (records, label = "name", value = "id") =>
    R.map(R.applySpec({ label: R.prop(label), value: R.prop(value) }), records),
);
export const objectToOptions: (o: Record<string, any>) => Option[] = R.pipe(
  R.toPairs,
  R.map(([value, label]) => ({ label, value } as Option)),
);
