import * as R from "ramda";

import { MARK_CLEARANCES_LOADED, UPDATE_TENANT } from "actions";
import { isBlank } from "utils/func";
import { adminEmphasis } from "utils/sharedStyles";

import { getIsCurrent, selectVersion } from "./versions";

export const PAYMENT_PROCESSORS = {
  none: "none",
  citybase: "citybase",
  stripe: "stripe",
  spreedly: "spreedly",
};

export default (state = { clearancesLoaded: [] }, { type, payload }) => {
  switch (type) {
    case UPDATE_TENANT:
      return R.mergeLeft(payload)(state);
    case MARK_CLEARANCES_LOADED:
      return R.over(R.lensProp("clearancesLoaded"), R.append(payload))(state);
    default:
      return state;
  }
};

export const getAccelaACAGuidanceMarkdown = R.path([
  "accela_configuration_attributes",
  "aca_guidance_markdown",
]);
export const getAccelaACAName = R.pathOr("ACA", ["accela_configuration_attributes", "aca_name"]);
export const getActionableCount = R.propOr(0, "requirement_applications_actionable_count");
export const getApplicationCount = R.propOr(0, "requirement_applications_count");
export const getBounds = R.prop("bounds");
export const getCenter = R.prop("center");
export const getDisclaimer = R.prop("disclaimer");
export const getFullName = R.prop("full_name");
export const getGeocoderName = R.prop("geocoder");
export const getGeocoderOptions = R.propOr({}, "geocoder_options");
export const getMunicipalityType = R.prop("municipality_type");
export const getName = R.prop("name");
export const getNewCount = R.propOr(0, "requirement_applications_new_count");
export const getPayableAccountsCount = R.propOr(0, "payable_accounts_count");
export const getSealURL = (tenant, type) => R.compose(R.prop(type), getSealURLs)(tenant);
export const getSealURLs = R.prop("seal_urls");
export const getSlug = R.prop("slug");
export const getTimezone = R.prop("timezone");
export const getUseCodes = R.propOr([], "use_codes");
export const getZoningClearanceHeader = R.prop("zoning_clearance_header");
export const isAccelaConnected = R.pathOr(false, ["accela_configuration_attributes", "connected"]);
export const isAccelaEnabled = R.compose(
  Boolean,
  R.path(["accela_configuration_attributes", "enabled"]),
);
export const getStateAbbreviation = R.prop("state_abbreviation");
export const getAddress = R.prop("address");
export const getZipcode = R.prop("zipcode");
export const getPhone = R.prop("phone");
export const getWebsiteURL = R.prop("website_url");

const getStaffTitle = R.prop("staff_title");
const getStaffName = R.prop("staff_name");
const getLanguages = R.prop("languages");

export const getActiveLanguages = R.compose(R.filter(R.propEq(true, "active")), getLanguages);
export const getIsMultilingual = R.pipe(getActiveLanguages, R.length, R.flip(R.gt)(1));

export const selectTenant = R.prop("tenant");
export const selectCenter = R.compose(R.prop("center"), selectTenant);
export const selectFullName = R.compose(getFullName, selectTenant);
export const selectLanguages = R.compose(getLanguages, selectTenant);
export const selectMunicipalityType = R.compose(getMunicipalityType, selectTenant);
export const selectName = R.compose(getName, selectTenant);
export const selectPhone = R.compose(getPhone, selectTenant);
export const selectStaffName = R.compose(getStaffName, selectTenant);
export const selectStaffTitle = R.compose(getStaffTitle, selectTenant);
export const selectStateAbbreviation = R.compose(getStateAbbreviation, selectTenant);
export const selectTenantID = R.compose(R.prop("id"), selectTenant);
export const selectTenantSlug = R.compose(getSlug, selectTenant);
export const selectUseCodes = R.compose(R.propOr([], "use_codes"), selectTenant);
export const selectWebsiteURL = R.compose(getWebsiteURL, selectTenant);
export const selectZipcode = R.compose(getZipcode, selectTenant);

// Payments related
export const getCityBaseAPCInvoiceURL = R.path(["payments_config", "citybase_apc_invoice_url"]);
export const selectSpreedlyKey = R.compose(
  R.path(["payments_config", "spreedly_public_key"]),
  selectTenant,
);
export const selectBackendPaymentProcessor = R.compose(
  R.path(["payments_config", "backend_processor"]),
  selectTenant,
);
export const selectPaymentProcessor = R.compose(
  R.path(["payments_config", "processor"]),
  selectTenant,
);
export const selectStripeKey = R.compose(
  R.path(["payments_config", "stripe_public_key"]),
  selectTenant,
);
export const selectArePaymentsRefundable = R.compose(
  R.pathOr(false, ["payments_config", "refundable"]),
  selectTenant,
);

export const selectUseCodeByName = (state, name) =>
  R.pipe(
    selectUseCodes,
    R.find(R.pipe(R.props(["full_name", "name"]), R.includes(name))),
    R.defaultTo({}),
  )(state);

export const selectSealURL = (state, type) => getSealURL(selectTenant(state), type);

const formatColor = (color) => (color.match(/^#/) ? color : `#${color}`);
export const getColors = R.applySpec({
  primary: R.compose(formatColor, R.propOr(adminEmphasis, "color_one")),
  secondary: R.compose(formatColor, R.propOr(adminEmphasis, "color_two")),
});

export const selectTenantVersionNumber = R.compose(R.prop("version_number"), selectTenant);
export const getTenantVersionIsHead = R.compose(isBlank, R.prop("last_version"));
export const selectTenantVersionIsHead = R.compose(getTenantVersionIsHead, selectTenant);
export const selectTenantVersionIsCurrent = (state) => {
  const activeVersionNumber = selectTenantVersionNumber(state);
  const version = selectVersion(state, activeVersionNumber);
  return getIsCurrent(version);
};
