import { useMemo } from "react";
import * as R from "ramda";

import { Field } from "types/entities";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/fields",
  baseKey: ["fields"],
});

export const useField = (id: number, opts = {}) => operation.useRead<Field>(id, opts);

export const useFields = (opts = {}) => operation.useRead<Field[]>(null, opts);

export const useFieldsByID = (ids: number[] = [], opts = {}) =>
  useFields({
    ...opts,
    select: R.filter((field: Field) => ids.includes(field.id)),
  });

export const useFieldWithChildren = (id: number, opts = {}) => {
  const { data: field, ...queryProps } = useField(id, opts);
  const { data: children = [] } = useFieldsByID(field?.child_ids, {
    enabled: field && field.child_ids.length > 0,
  });

  const fieldWithChildren = useMemo(() => {
    if (!field) return null;
    if (field.child_ids.length && !children.length) return null;
    return R.assoc("children", children, field);
  }, [children, field]);

  return { ...queryProps, data: fieldWithChildren };
};

export const useFieldByKey = (key: string, opts = {}) =>
  useFields({
    ...opts,
    select: R.find(R.propEq(key, "key")),
  });
