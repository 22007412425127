import * as R from "ramda";

const UNSUPPORTED_FIELD_TYPES = ["text_insert", "signature", "sensitive"];

export const rejectUnsupportedFields = R.reject(
  R.propSatisfies(R.flip(R.includes)(UNSUPPORTED_FIELD_TYPES), "field_type"),
);

const baseAdminURL = (changeset) => `/admin/changesets/${changeset.id}`;

export const getAdminEditURL = (changeset, { viewType } = {}) => {
  const url = `${baseAdminURL(changeset)}/edit`;
  if (viewType) {
    return `${url}/${viewType}`;
  }
  return url;
};
export const getAdminReviewURL = (changeset) => `${baseAdminURL(changeset)}/review`;

const selectMutationsBy = (key, value) => R.filter(R.propEq(value, key));
export const selectRequirementMutations = selectMutationsBy("record_type", "requirement");
export const selectRequirementMutationsOfType = (mutations, type) =>
  R.compose(selectRequirementMutations, selectMutationsBy("type", type))(mutations);
export const selectAnswerMutations = selectMutationsBy("record_type", "field");

export const isChangeset = R.ifElse(
  R.complement(R.isNil),
  R.propEq("Changeset", "type"),
  R.always(false),
);

export const getInvalidFieldIDs = R.pipe(R.prop("errors"), R.keys, R.map(Number));

export const getAsPreviousRecord = (changeset) =>
  R.assoc("answers", R.prop("previous_answers", changeset), changeset);
