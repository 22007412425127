import { useEffect, useReducer, useRef, useState } from "react";
import * as R from "ramda";

import useIsMounted from "hooks/useIsMounted";

// A reducer function that processes actions to update the state of an AnsweredEntity.
// @function
// @name answerReducer
// @param {Object} state - The current state of the AnsweredEntity.
// @param {Object} action - The action to be processed.
// @returns {Object} The updated state of the AnsweredEntity.
const answerReducer = (state, action) => {
  switch (action.type) {
    case "loaded":
      return action.record;
    case "setAnswer":
      return R.assocPath(["answers", action.key], action.value, state);
    case "clearAnswer":
      return R.dissocPath(["answers", action.key], state);
    case "update": {
      const existingAnswers = R.propOr({}, "answers", state);
      const existingErrors = R.propOr({}, "errors", state);
      const updatedRecord = R.assoc("errors", existingErrors, R.prop("record", action));
      const newAnswers = R.prop("answers", updatedRecord);
      const mergedAnswers = R.mergeRight(newAnswers, existingAnswers);

      return R.assoc("answers", mergedAnswers, updatedRecord);
    }

    case "setError":
      return R.assocPath(["errors", action.id], action.error, state);

    case "clearError":
      return R.dissocPath(["errors", action.id], state);

    case "clearErrors":
      return R.dissoc("errors", state);

    default:
      throw new Error();
  }
};

// A custom React hook that manages the state and actions of a form.
// @function
// @name useAnswerContextManager
// @param {Object} [initialParams] - An object containing the initial record and loading state from a react-query.
// @param {Object} [initialParams.data] - The initial form record.
// @param {boolean} [initialParams.isLoading] - The initial loading state.
// @param {*} mutation - The function used to update the server with the new form data.
// @param {Object} [options] - Additional options for the hook.
// @param {boolean} [options.ephemeral] - Determines if the hook should only save changed fields.
// @returns {Object} An object containing the form state and functions for handling form actions.
const useAnswerContextManager = (
  { data: initialRecord = {}, isLoading = false },
  save,
  { ephemeral = false, debug = false } = {},
) => {
  const isMounted = useIsMounted();

  const [isLoaded, setIsLoaded] = useState(false);

  const [record, dispatch] = useReducer(answerReducer, initialRecord);
  const recordRef = useRef(record);
  useEffect(() => {
    recordRef.current = record;
  }, [record]);
  const [changedFields, setChangedFields] = useState([]);

  const [pending, setPending] = useState(false);

  const [processing, setProccessing] = useState(false);
  const [saveQueue, setSaveQueue] = useState([]);
  const [afterSaveQueue, setAfterSaveQueue] = useState([]);

  const consoleDebug = (...args) => {
    if (debug) {
      console.log(...args);
    }
  };

  useEffect(() => {
    const hasQueue = afterSaveQueue.length + saveQueue.length > 0;
    if (!processing) {
      consoleDebug("SET PENDING", hasQueue);
      setPending(hasQueue);
      if (hasQueue) runNext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterSaveQueue, saveQueue, processing]);

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch({ type: "loaded", record: initialRecord });
      setIsLoaded(true);
    }
  }, [isLoaded, isLoading, initialRecord]);

  const run = async (fn) => {
    setPending(true);
    await fn();
    setProccessing(false);
  };

  const runNext = async () => {
    if (processing) {
      return;
    }
    consoleDebug("==================");
    consoleDebug("RUN NEXT");

    setProccessing(true);

    if (saveQueue.length > 0) {
      isMounted(() => {
        run(async () => {
          const answers = R.pickAll(saveQueue, R.propOr({}, "answers", recordRef.current));
          consoleDebug("SAVING", saveQueue);
          setSaveQueue([]);

          try {
            const result = await save({ answers });
            consoleDebug("UPDATED", result);
            dispatch({ type: "update", record: result });
          } catch (e) {
            const errors = R.pathOr({}, ["response", "data", "errors"], e);

            R.forEachObjIndexed((error, key) => {
              dispatch({ type: "setError", id: key, error });
            }, errors);
          }
        });
      });
    } else if (afterSaveQueue.length) {
      const nextAction = afterSaveQueue[0];
      setAfterSaveQueue(R.tail(afterSaveQueue));
      isMounted(async () => {
        if (nextAction.validate) {
          throw new Error("Validation on nextAction unimplemented (not required for changesets)");
        }

        run(async () => {
          consoleDebug("RUN NEXT ACTION", nextAction);
          await nextAction.fn();
        });
      });
    } else {
      setProccessing(false);
    }
  };

  const onChange = (field, value) => {
    setChangedFields((fields) => R.uniqBy(R.prop("key"), fields.concat(field)));

    dispatch({ type: "clearError", id: field.id });
    dispatch({ type: "setAnswer", key: field.key, value });
  };

  const clearError = ({ id }) => {
    dispatch({ type: "clearError", id });
  };

  const setError = ({ id }, error) => {
    dispatch({ type: "setError", id, error });
  };

  const onSave = (...fields) => {
    setSaveQueue((prevSaveQueue) => {
      const fieldsToSave = ephemeral ? [...changedFields, ...fields] : fields;
      return R.uniq([...prevSaveQueue, ...R.map(R.prop("key"), fieldsToSave)]);
    });
  };

  const onAction = (fn, { validate = false } = {}) => {
    setPending(true);
    setAfterSaveQueue((prevAfterSaveQueue) => [...prevAfterSaveQueue, { fn, validate }]);
  };

  return {
    record,
    onChange,
    setError,
    clearError,
    onSave,
    onAction,
    pending,
    setPending,
    setSaveQueue,
    changedFields,
    dispatch,
    isLoading: !isLoaded,
  };
};

export default useAnswerContextManager;
