import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import ErrorMessage from "components/project_form/ErrorMessage";
import Label from "components/project_form/Label";
import Text from "containers/Text";
import useFocusedState from "hooks/useFocusedState";
import { getAnswerForField, getErrorForField } from "reducers/answerContexts";
import { getType, isList } from "reducers/fields";
import { isChangeset } from "selectors/changesets";
import { formatId } from "utils/format";
import { isBlank, isString } from "utils/func";
import { childrenPropType, fieldPropType } from "utils/sharedPropTypes";

import styles from "./Field.scss";
import { fieldForInputType } from "./fieldLookup";

export const dataAttrsForField = (field, listItem = false) => {
  let uiType;
  if (listItem) {
    uiType = R.path(["ui", "form_input", "item_ui", "type"], field);
  } else {
    uiType = R.path(["ui", "form_input", "type"], field);
  }
  const dataAttrs = {
    "data-field-id": field.id,
    "data-field-key": field.key,
    "data-field-name": field.field_label,
    "data-field-type": field.field_type,
    "data-field-ui-type": uiType,
  };

  if (!listItem && uiType === "list") {
    dataAttrs["data-field-list-item-ui-type"] = R.path(
      ["ui", "form_input", "item_ui", "type"],
      field,
    );
  }
  return dataAttrs;
};

const DefaultLayout = ({
  field,
  onFocus,
  onBlur,
  children,
  horizontalLabel,
  behaviorType,
  className,
  id,
  isNested = false,
}) => {
  const fieldTypeStyleKey = R.pipe(
    R.filter(Boolean),
    R.join("-"),
  )([getType(field), behaviorType, isList(field)]);

  const dataAttrs = isNested ? {} : dataAttrsForField(field);
  return (
    <div
      className={classnames(className, styles.fieldContainer, styles[fieldTypeStyleKey], {
        [styles.horizontal]: horizontalLabel,
      })}
      {...dataAttrs}
      onFocus={onFocus}
      onBlur={onBlur}
      id={id}
    >
      {children}
    </div>
  );
};

DefaultLayout.propTypes = {
  field: fieldPropType,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: childrenPropType.isRequired,
  animateFieldChanges: PropTypes.bool,
  horizontalLabel: PropTypes.bool,
  behaviorType: PropTypes.string,
  isNested: PropTypes.bool,
};

export const LayoutWithLock = ({ isLocked, children, ...props }) => (
  <div className={styles.fieldContainerWithLock}>
    <div className={styles.field}>
      <DefaultLayout {...props}>{children}</DefaultLayout>
    </div>
    {isLocked && (
      <div className={styles.fieldAnnotation}>
        <Icon icon="lock" size="lg" />
      </div>
    )}
  </div>
);

const isUnanswered = (value) =>
  R.ifElse(R.is(Object), R.pipe(R.values, R.all(isUnanswered)), isBlank)(value);

const FormField = ({
  Layout = DefaultLayout,
  hideLabel,
  hideRequired,
  hideHelp,
  animateFieldChanges,
  behaviorType,
  fieldComponent,
  hideIfUnanswered,
  horizontalLabel,
  hideError,
  children,
  className,
  isNested,
  isLocked,
  changeType,
  ...props
}) => {
  const [isFocused, onFocus, onBlur] = useFocusedState(false);
  const { field, answerContext } = props;
  const {
    record,
    context,
    onChange: onChangeAnswerContext,
    onSave: onSaveAnswerContext,
    setError: setAnswerContextError,
    clearError: clearAnswerContextError,
  } = answerContext;

  if (!record) {
    console.error("An AnswerContext is required to render a field", answerContext);
  }

  // [jb] Component field children still get their value passed in by the parent until we add key_path to fields
  const value = R.isNil(props.value) ? getAnswerForField(record, field) : props.value;

  const onChange = (value) => {
    onChangeAnswerContext(field, value);
    if (props.onChange) props.onChange({ field, value, answerContext });
  };

  const onSave = () => {
    onSaveAnswerContext(field);
    if (props.onSave) props.onSave({ field, answerContext });
  };

  const onClear = () => {
    onChange(null);
    setTimeout(onSave, 0);
  };

  const setError = (error) => setAnswerContextError(field, error);
  const clearError = () => clearAnswerContextError(field);

  const error = getErrorForField(record, field);
  const unanswered = isUnanswered(value);

  const uiType = field.ui?.form_input?.type || "text";

  if (hideIfUnanswered && unanswered && uiType !== "instruction") {
    return null;
  }

  const Field = fieldComponent || fieldForInputType(uiType, { behaviorType });

  const required =
    !hideRequired &&
    (field.required || (!isChangeset(record) && R.propOr(false, "all_fields_required", context)));
  const labelProps = {
    field,
    required,
    hideHelp,
    horizontalLabel,
    behaviorType,
  };
  const labelHidden =
    hideLabel ||
    (behaviorType !== "issued" &&
      R.includes(uiType, ["instruction", "check_box", "terms_and_condition", "none"]));

  return (
    <>
      <Layout
        field={field}
        onFocus={onFocus}
        onBlur={onBlur}
        animateFieldChanges={animateFieldChanges}
        horizontalLabel={horizontalLabel}
        behaviorType={behaviorType}
        className={classnames(className, {
          [styles.answerChangedContainer]: !!changeType,
        })}
        isNested={isNested}
        isLocked={isLocked}
        id={formatId(field.admin_name || "")}
      >
        {changeType && <RenewalChangeMsg changeType={changeType} />}
        {!labelHidden && <Label {...labelProps} />}
        <Field
          {...props}
          labelId={field.key}
          onBlur={onBlur}
          required={required}
          isFocused={isFocused}
          labelProps={labelProps}
          value={value}
          onChange={onChange}
          onSave={onSave}
          onClear={onClear}
          setError={setError}
          clearError={clearError}
        />
        {!hideError && error && isString(error) && <ErrorMessage error={error} />}
      </Layout>
      {children}
    </>
  );
};

function RenewalChangeMsg({ changeType }) {
  const msgType = changeType === "+" ? "added" : "updated";
  const icon = changeType === "+" ? "star" : "sync";

  return (
    <div className={styles.answerChangedMsg}>
      <Icon icon={icon} />
      <span>
        <Text t={`requirement_application.answer_${msgType}`} />
      </span>
    </div>
  );
}

FormField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  hideLabel: PropTypes.bool,
  hideRequired: PropTypes.bool,
  hideError: PropTypes.bool,
  hideHelp: PropTypes.bool,
  required: PropTypes.bool,
  behaviorType: PropTypes.string,
  animateFieldChanges: PropTypes.bool,
  fieldComponent: PropTypes.element,
  hideIfUnanswered: PropTypes.bool,
  horizontalLabel: PropTypes.bool,
  children: childrenPropType,
  className: PropTypes.string,
  isNested: PropTypes.bool,
};

export default FormField;
