import React from "react";
import * as R from "ramda";
import { useSelector } from "react-redux";

import RequirementHandoffCard from "components/projects/handoff/RequirementHandoffCard";
import { useAnswerContext } from "containers/withAnswerContext";
import * as Departments from "reducers/departments";
import { getName as getDepartmentName, selectDepartmentsByID } from "reducers/departments";
import {
  getCurrentRequirementApplications,
  getRequirementRequirementApplicationID,
} from "reducers/projects";
import {
  filterRequirementsByDepartmentID,
  getDepartmentID,
  selectRequirementsByID,
} from "reducers/requirements";
import standardStyles from "sharedStyles/standard.scss";
import routes from "utils/routes";

import styles from "../Handoff.scss";

const RequirementsByDepartment = () => {
  const { record: project } = useAnswerContext();
  const requirementApplications = getCurrentRequirementApplications(project);
  const requirementIDs = R.map(getRequirementRequirementApplicationID, requirementApplications);
  const requirements = useSelector((state) => selectRequirementsByID(state, requirementIDs));

  const departments = useSelector((state) =>
    R.pipe(
      R.map(getDepartmentID),
      R.reject(R.isNil),
      selectDepartmentsByID(state),
      R.uniqBy(R.prop("id")),
      R.sortBy(getDepartmentName),
    )(requirements),
  );

  return (
    <div className={styles.departments}>
      {R.map((department) => {
        const requirementsForDepartment = R.pipe(
          filterRequirementsByDepartmentID(R.prop("id", department)),
          R.sortBy(R.prop("name")),
        )(requirements);
        return (
          <div className={styles.departmentContainer} key={`department[${department.id}]`}>
            <div className={standardStyles.headMd}>
              <div className={styles.departmentName}>{Departments.getName(department)}</div>
            </div>

            <div className={styles.requirementsContainer}>
              {R.map((requirement) => {
                const requirementApplication = R.find(
                  (pa) => R.propEq(getRequirementRequirementApplicationID(pa), "id", requirement),
                  requirementApplications,
                );

                return (
                  <RequirementHandoffCard
                    key={`requirement[${requirement.id}]`}
                    requirement={requirement}
                    requirementApplication={requirementApplication}
                    href={routes.requirementApplication(
                      project.id,
                      requirementApplication.requirement_id,
                    )}
                    department={department}
                  />
                );
              }, requirementsForDepartment)}
            </div>
          </div>
        );
      }, departments)}
    </div>
  );
};

export default RequirementsByDepartment;
