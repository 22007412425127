import axios from "axios";

import { getBounds, getCenter, getGeocoderOptions } from "reducers/tenant";
import { reportError } from "services/errorReporter";
import { isBlank } from "utils/func";

import AbstractClassError from "./abstractClassError";
import ReverseGeocodingAddressNotFoundError from "./reverseGeocodingAddressNotFoundError";

export default class Base {
  constructor(tenant, field, projectID = null) {
    this.bounds = getBounds(tenant);
    this.center = getCenter(tenant);
    this.options = getGeocoderOptions(tenant);
    this.tenant = tenant;
    this.field = field;
    this.projectID = projectID;
  }

  suggestionURL(_input) {
    throw new AbstractClassError();
  }

  reverseGeocodeURL(_lat, _lng) {
    throw new AbstractClassError();
  }

  jsonToSuggestions(_json) {
    throw new AbstractClassError();
  }

  jsonToAddress(_json) {
    throw new AbstractClassError();
  }

  async suggestionToAddress(_suggestion) {
    throw new AbstractClassError();
  }

  async fetchSuggestions(input) {
    if (!input || isBlank(input)) return [];

    try {
      const { data } = await axios.get(this.suggestionURL(input));
      return this.jsonToSuggestions(data);
    } catch (e) {
      reportError(e);
      throw e;
    }
  }

  async reverseGeocode(lat, lng) {
    try {
      const { data: a } = await axios.get(this.reverseGeocodeURL(lat, lng));
      return this.jsonToAddress(a, lat, lng);
    } catch (e) {
      if (e instanceof ReverseGeocodingAddressNotFoundError) return null;
      reportError(e);
      throw e;
    }
  }
}
