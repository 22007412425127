import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { classNamePropType } from "utils/sharedPropTypes";

import styles from "./Icon.scss";

const Icon = ({
  icon,
  faStyle = "light",
  size = undefined,
  inverse = false,
  dark = false,
  light = false,
  fw = false,
  className = [],
  color = null,
  children = undefined,
  ...props
}) => (
  <i
    className={classnames(
      "icon",
      styles.icon,
      `fa-${faStyle}`,
      `fa-${icon}`,
      {
        [styles.darkIcon]: dark,
        [styles.lightIcon]: light,
        [`fa-${size}`]: !!size,
        "fa-inverse": inverse,
        "fa-fw": fw,
      },
      ...Array.of(className),
    )}
    style={color ? { color } : undefined}
    aria-hidden="true"
    children={children}
    {...props}
  />
);
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  faStyle: PropTypes.string,
  size: PropTypes.string,
  inverse: PropTypes.bool,
  className: classNamePropType,
};

export const IconStack = ({ size, className, children }) => (
  <span
    className={classnames(
      "fa-stack",
      {
        [`fa-${size}`]: !!size,
      },
      ...Array.of(className),
    )}
  >
    {children}
  </span>
);

export const CircularIcon = ({
  icon,
  size,
  iconColor,
  bgColor,
  inverse = false,
  faStyle = "light",
  className = [],
}) => (
  <IconStack size={size} className={className}>
    <Icon
      icon="circle"
      className="fa-stack-2x"
      dark={!inverse}
      light={inverse}
      faStyle="solid"
      color={bgColor}
    />
    <Icon
      icon={icon}
      faStyle={faStyle}
      className="fa-stack-1x"
      color={iconColor}
      inverse={!inverse}
    />
  </IconStack>
);
CircularIcon.propTypes = Icon.propTypes;

export default Icon;
