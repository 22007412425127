import { useCallback, useEffect, useState } from "react";
import * as R from "ramda";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import { useCurrentTenantInfo } from "contexts/currentTenant";
import useGeocoder from "hooks/useGeocoder";
import { getAnswerForField, getAnswersForFields } from "reducers/answerContexts";
import { rejectCalculatedFields, selectFieldsByID, selectSystemFieldBySlug } from "reducers/fields";
import {
  getIsZoningEnabled,
  getProjectRequirementFieldIDs,
  getUnansweredCountForFields,
  getZoningFieldIDs,
  selectActiveUseCodeConditionFields,
  selectUseCode,
  selectZoning,
} from "reducers/projects";
import { selectTenant } from "reducers/tenant";
import zoningCache from "services/ZoningCache";
import analytics from "services/analytics";
import { ADDRESS_CHOSEN, ADDRESS_TYPEAHEAD } from "services/tracking";
import zoningApi from "services/zoningApi";
import { getFullAddress } from "utils/address";
import { isPresent } from "utils/func";
import { getSlug as getUseCodeSlug } from "utils/useCode";
import { getPermission, getZones, shouldSnapToFeature } from "utils/zoning";

const trackAddressTypeahead = ({ inZone, triedToSnap, didSnap, addressObj }) => {
  if (!addressObj) return;

  analytics.track(ADDRESS_TYPEAHEAD, {
    inZone,
    triedToSnap,
    didSnap,
    fullAddress: addressObj.full_address,
    latitude: addressObj.latitude,
    longitude: addressObj.longitude,
  });
};

const trackAddressChosenEvent = ({ source, addressObj, mapType }) => {
  if (!addressObj) return;

  analytics.track(ADDRESS_CHOSEN, {
    mapType,
    source,
    fullAddress: addressObj.full_address,
    latitude: addressObj.latitude,
    longitude: addressObj.longitude,
    inZone: !R.isEmpty(addressObj.zones),
  });
};

const useRefreshZoning = (addressField, answerContext) => {
  const { record, onChange } = answerContext;
  const useCode = useSelector((state) => selectUseCode(state, record));
  const zoningField = useSelector((state) => selectSystemFieldBySlug(state, "zoning"));
  const fields = useSelector((state) => selectActiveUseCodeConditionFields(state, record));
  const answers = getAnswersForFields(record, fields);
  const { id: tenantID } = useCurrentTenantInfo();

  return useCallback(
    async (value) => {
      value ||= getAnswerForField(record, addressField);
      if (value.latitude == null) return null;
      const useCodeSlug = getUseCodeSlug(useCode);
      let zoningData;
      if (useCodeSlug)
        zoningData = await zoningCache.forPoint(
          useCodeSlug,
          tenantID,
          answers,
          value.latitude,
          value.longitude,
        );
      else
        zoningData = await zoningCache.forPointWithoutClearance(
          tenantID,
          value.latitude,
          value.longitude,
        );

      onChange(zoningField, zoningData);
      return zoningData;
    },
    [useCode, onChange, zoningField, addressField, answers, record, tenantID],
  );
};

const useSnapToFeature = (addressField, updateLocation) => {
  const tenant = useSelector(selectTenant);
  const geocoder = useGeocoder(addressField);
  const [address, setAddress] = useState(null);

  const {
    data: suggestions,
    isFetched,
    isSuccess,
  } = useQuery({
    async queryFn() {
      const suggested_snap_points = await zoningApi.getSnapSuggestions({
        latitude: address?.latitude,
        longitude: address?.longitude,
      });

      return Promise.all(
        suggested_snap_points.map(({ point: { latitude, longitude } }) =>
          geocoder.reverseGeocode(latitude, longitude),
        ),
      );
    },
    enabled: isPresent(address),
  });

  useEffect(() => {
    if (!isFetched || !isSuccess) return;
    if (!suggestions) return;

    const suggestedAddress = R.compose(
      R.head,
      R.filter((a) => address.full_address === a.text),
    )(suggestions);
    const didSnap = !!suggestedAddress;

    trackAddressTypeahead({
      inZone: didSnap,
      triedToSnap: true,
      didSnap,
      addressObj: address,
    });

    if (!didSnap) return;

    updateLocation(R.mergeRight(address, suggestedAddress));
  }, [isFetched, isSuccess, suggestions, address, updateLocation]);

  const shouldSnap = (address) => shouldSnapToFeature(tenant, address);

  return { snap: setAddress, shouldSnap };
};

const useOnLocationChange = (addressField, answerContext, { setIsInCityBoundary, mapType }) => {
  const refreshZoning = useRefreshZoning(addressField, answerContext);
  const { id: tenantID } = useCurrentTenantInfo();

  const geocoder = useGeocoder(addressField);
  const { onChange, onSave, setPending } = answerContext;

  return useCallback(
    async (value, source) => {
      const { latitude, longitude } = value;
      setPending(true);
      const zoning = await refreshZoning(value);
      let inBoundary = true;
      if (!zoning || R.isEmpty(getZones(zoning))) {
        inBoundary = await zoningCache.isInBoundary(tenantID, latitude, longitude);
        setIsInCityBoundary(inBoundary);
      } else setIsInCityBoundary(true);
      if (!getFullAddress(value)) {
        const address = await geocoder.reverseGeocode(latitude, longitude);
        value = R.assoc("full_address", R.prop("text", address), value);
        onChange(addressField, value);
      }

      if (inBoundary) {
        onSave(addressField);
      } else {
        setPending(false);
      }

      trackAddressChosenEvent({ mapType, source, addressObj: value });

      return value;
    },
    [
      addressField,
      geocoder,
      mapType,
      onChange,
      onSave,
      refreshZoning,
      setIsInCityBoundary,
      setPending,
      tenantID,
    ],
  );
};

const useOnAddressChange = (addressField, onLocationChange) => {
  const { shouldSnap, snap } = useSnapToFeature(addressField, onLocationChange);

  return async ({ value }) => {
    if (!value) return;

    const address = await onLocationChange(value, "typeahead");
    if (shouldSnap(address)) {
      snap(address);
    } else {
      trackAddressTypeahead({
        inZone: true,
        triedToSnap: false,
        didSnap: false,
        addressObj: address,
      });
    }
  };
};

// Filter out zoning field ids already dispalyed on form if we're on a form
const getFilteredZoningFieldIDs = (answerContext) => {
  const record = R.prop("record", answerContext);
  const zoningFieldIDs = getZoningFieldIDs(record);

  if (!R.pathEq("RequirementSection", ["context", "type"], answerContext)) {
    return zoningFieldIDs;
  }
  const requirement = R.path(["context", "requirement"], answerContext);
  if (!requirement) return zoningFieldIDs;

  const requirementFieldIDs = getProjectRequirementFieldIDs(record, requirement);
  return R.without(requirementFieldIDs, zoningFieldIDs);
};

const useFilteredInlineZoningFields = (answerContext) => {
  const zoningFieldIDs = getFilteredZoningFieldIDs(answerContext);
  return useSelector((state) => rejectCalculatedFields(selectFieldsByID(state, zoningFieldIDs)));
};

export const useZoningData = (answerContext) => {
  const { record } = answerContext;
  const zoning = useSelector((state) => selectZoning(state, record));
  const isZoningEnabled = getIsZoningEnabled(record);
  const permission = getPermission(zoning);
  const category = R.prop("category", permission || {});

  return {
    category,
    isZoningEnabled,
    permission,
    zoning,
  };
};

const useZoningField = (addressField, answerContext) => {
  const { record } = answerContext;
  const refreshZoning = useRefreshZoning(addressField, answerContext);

  const conditionFields = useFilteredInlineZoningFields(answerContext);
  const unansweredFieldCount = getUnansweredCountForFields(record, conditionFields);

  const { category, isZoningEnabled, permission, zoning } = useZoningData(answerContext);

  const [mapType, setMapType] = useState(isZoningEnabled ? "default" : "zoneless");
  const [isInCityBoundary, setIsInCityBoundary] = useState(true);

  const onLocationChange = useOnLocationChange(addressField, answerContext, {
    mapType,
    setIsInCityBoundary,
  });

  const onAddressChange = useOnAddressChange(addressField, onLocationChange);

  const onConditionChange = () => refreshZoning();

  return {
    isInCityBoundary,
    isZoningEnabled,

    conditionFields,
    unansweredFieldCount,
    mapType,
    setMapType,
    zoning,
    permission,
    category,

    onAddressChange,
    onLocationChange,
    onConditionChange,
  };
};

export default useZoningField;
