import React from "react";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";

import Icon from "components/Icon";
import errorStyles from "sharedStyles/errors.scss";

import withFocusToggle from "../utilities/withFocusToggle";
import styles from "./NumberField.scss";
import { textInput } from "./behaviors";

export const DisabledCurrencyField = ({ value }) => (
  <div className={styles.disabledContainer}>
    <Icon icon="usd-circle" faStyle="regular" />
    <NumericFormat
      className={styles.disabled}
      value={value || ""}
      thousandSeparator
      fixedDecimalScale
      decimalScale={2}
      displayType="text"
    />
  </div>
);
export const disabled = DisabledCurrencyField;

export const BareCurrencyField = ({
  labelId,
  value,
  onChange,
  onBlur,
  error,
  required,
  focus,
  unfocus,
  focused,
  placeholder,
}) => (
  <div className={styles.container}>
    <span
      className={classnames(styles.prefix, {
        [errorStyles.errorBorder]: error,
        [styles.focused]: focused,
      })}
    >
      $
    </span>
    <NumericFormat
      className={classnames(styles.hasPrefix, {
        [errorStyles.errorBorder]: error,
        [styles.focused]: focused,
      })}
      value={value}
      onChange={onChange}
      onBlur={(e) => {
        onBlur(e);
        unfocus();
      }}
      onFocus={focus}
      thousandSeparator
      type="tel"
      fixedDecimalScale
      decimalScale={2}
      placeholder={placeholder}
      aria-labelledby={labelId}
      aria-invalid={!!error}
      aria-required={!!required}
    />
  </div>
);

const CurrencyField = withFocusToggle(textInput(BareCurrencyField));
export default CurrencyField;
