/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from "react";
import { selectIsRequestPendingForType } from "#ui/reducers/network";
import classnames from "classnames";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";

import { changeVersion, setAdminBarExpanded } from "actions";
import ExternalLink from "components/ExternalLink";
import Icon from "components/Icon";
import Loader from "components/Loader";
import GithubLink from "components/config/GithubLink";
import { SelectInput } from "components/forms/Fields";
import { useAnswerContext } from "containers/withAnswerContext";
import { useIsInConfigSection } from "contexts/applicationSection";
import { useServerVariables } from "contexts/serverVariables";
import { useSession } from "contexts/session";
import { useVersions } from "queries/config/versions";
import { selectTenantVersionIsHead, selectTenantVersionNumber } from "reducers/tenant";
import { selectIsAdminBarExpanded } from "reducers/ui";
import { buildURL, getPathFromURL } from "utils/urls";

import styles from "./AdminBar.scss";

const classNameForDeliveryStage = (deliveryStage) =>
  R.propOr(styles.stageStaging, deliveryStage, {
    production: styles.stageProduction,
    local: styles.stageLocal,
  });

// TODO: refactor this from Redux state to a context that provides this state
const useAdminBarState = () => {
  const { isSuperuser: visible } = useSession();
  const expanded = useSelector(selectIsAdminBarExpanded);
  const activeVersion = useSelector(selectTenantVersionNumber);
  const activeIsHead = useSelector(selectTenantVersionIsHead);
  const isInConfig = useIsInConfigSection();
  const dispatch = useDispatch();
  const toggle = useCallback(() => dispatch(setAdminBarExpanded(!expanded)), [dispatch, expanded]);

  return {
    visible,
    expanded: isInConfig || expanded,
    toggle: isInConfig ? null : toggle,
    activeVersion,
    activeIsHead,
  };
};

export const AdminBarHandle = ({ className = [] }) => {
  const { visible, expanded, toggle, activeVersion } = useAdminBarState();
  const { deliveryStage } = useServerVariables();

  if (!visible || expanded) return null;
  return (
    <div
      title="Admin Bar"
      data-version={activeVersion}
      data-admin-bar
      className={classnames(styles.collapsed, classNameForDeliveryStage(deliveryStage), className)}
      onClick={toggle}
    >
      <Icon
        icon="chevron-double-down"
        faStyle="solid"
        size="xs"
        className={[styles.collapsedIcon]}
      />
    </div>
  );
};

const toOptions = R.map(({ name, number, current }) => ({
  value: number,
  label: `${number}. ${current ? "CURRENT: " : ""}${name}`,
}));

export const AdminBar = ({ useURLParamToSwitchVersions = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deliveryStage } = useServerVariables();
  const { record: project = {} } = useAnswerContext() || {};
  const { visible, expanded, toggle, activeVersion, activeIsHead } = useAdminBarState();
  const { data: versions = [], isLoading: isLoadingVersions } = useVersions({
    enabled: visible && expanded,
    select: toOptions,
  });

  const onChangeVersion = useCallback(
    (version) => {
      if (useURLParamToSwitchVersions) {
        // this approach is less sticky but needed for the Home page which switches to current version otherwise
        navigate(getPathFromURL(buildURL({ search: `?version=${version}` })));
      }
      dispatch(changeVersion({ version_number: version }));
    },
    [dispatch, navigate, useURLParamToSwitchVersions],
  );

  const isLoadingInitialState = useSelector((s) =>
    selectIsRequestPendingForType(s, "InitialState"),
  );

  const isLoading = isLoadingVersions || isLoadingInitialState;
  if (!visible || !expanded) return null;

  return (
    <div className={styles.wrapper} data-admin-bar data-version={activeVersion}>
      <div
        className={classnames(styles.stage, classNameForDeliveryStage(deliveryStage))}
        onClick={toggle}
      >
        <div>
          <Icon icon="compass" faStyle="solid" />
          <span>{deliveryStage}</span>
        </div>
      </div>
      <div className={styles.git}>
        <GithubLink />
      </div>
      {project.id && (
        <div>
          <ExternalLink href={`/admin/projects/${project.id}/debug`} className="btn-sm" hideIcon>
            Debug
          </ExternalLink>
        </div>
      )}
      <div className={styles.version}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <SelectInput
              options={versions}
              rawValue={activeVersion}
              input={{
                name: "version",
                onBlur: R.T,
                onChange: onChangeVersion,
              }}
              meta={{}}
              compact
            />
            <Link to={`/config/versions/${activeVersion}`} className="btn-sm">
              {activeIsHead ? "Commit" : "View"}
            </Link>
          </>
        )}
      </div>
      {toggle && (
        <div title="close" data-close className={styles.close} onClick={toggle}>
          <Icon icon="times" />
        </div>
      )}
    </div>
  );
};
