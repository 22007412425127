import React from "react";
import * as R from "ramda";

import { CircularIcon } from "components/Icon";
import { useGetText } from "containers/Text";
import { permissionPropType } from "utils/sharedPropTypes";
import vars, { darkText } from "utils/sharedStyles";

import styles from "./Permission.scss";

const usePermissionCategories = (permission) => {
  const permissionCategory = R.propOr("permitted", "category", permission);
  const prohibited = permissionCategory === "prohibited";
  const permitted = permissionCategory === "permitted";
  const other = !prohibited && !permitted;
  return { permissionCategory, prohibited, permitted, other };
};

export const Icon = ({ permission, size = "2x" }) => {
  const { permissionCategory, other } = usePermissionCategories(permission);
  return (
    <div className={styles.iconContainer}>
      <CircularIcon
        size={size}
        icon={permissionCategory === "permitted" ? "check" : "exclamation"}
        iconColor={other ? "#333" : "#fff"}
        bgColor={vars[permissionCategory]}
      />
    </div>
  );
};

export const Message = ({ permission }) => {
  const { permissionCategory, prohibited } = usePermissionCategories(permission);
  const getText = useGetText();
  // eslint-disable-next-line prefer-const
  let { display_name, display_verb } = permission;
  if (!display_name) display_name = getText(`guides.location.${permissionCategory}`);
  const header = getText(`guides.summary.${prohibited ? "prohibited" : "notprohibited"}_header`, {
    display_name,
    display_verb,
  });
  return <h1 style={{ color: prohibited ? vars[permissionCategory] : darkText }}>{header} </h1>;
};

export const Permission = ({ permission }) => (
  <div className={styles.container}>
    <Icon permission={permission} />
    <Message permission={permission} />
  </div>
);

Permission.propTypes = {
  permission: permissionPropType.isRequired,
};

export default Permission;
