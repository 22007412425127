import * as R from "ramda";

import { Guide } from "types/entities";

import resourceOperations from "./resourceOperations";

const operation = resourceOperations({
  basePath: "/api/guides",
  baseKey: ["guides"],
});

export const useGuides = <T = Guide[]>(opts = {}) =>
  operation.useRead<T>(null, { staleTime: 0, ...opts });

export const useGuide = (id: number, opts = {}) =>
  useGuides<Guide>({
    ...opts,
    select: R.prop(id),
  });
