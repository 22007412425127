import { useQuery } from "react-query";

import keys from "queries/keys";
import ocClient from "services/ocClient";

export const useRenewal = (projectID: number, requirementApplicationID: number, opts = {}) =>
  useQuery(
    keys.requirementApplicationRenewals(projectID, requirementApplicationID),
    async () => {
      const response = await ocClient.get(
        `/api/requirement_applications/${requirementApplicationID}/renewal`,
      );
      return response.data;
    },
    { staleTime: 0, ...opts },
  );
