import * as R from "ramda";

import { overlaps } from "utils/func";

export const idsForType = (type, filters = []) =>
  R.map(R.prop("id"), R.filter(R.propEq(type, "type"), filters));

const filterUseCodes = (useCodes, filters = []) => {
  const includeCategories = idsForType("include_category", filters);
  const excludeCategories = idsForType("exclude_category", filters);

  let filteredUseCodes = useCodes;
  if (!R.isEmpty(includeCategories))
    filteredUseCodes = R.filter(
      (u) => overlaps(u.category_ids, includeCategories),
      filteredUseCodes,
    );

  return R.reject((u) => overlaps(u.category_ids, excludeCategories), filteredUseCodes);
};

export default filterUseCodes;
