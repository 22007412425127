/**
 * This module handles the key generation and invalidation for our react-query based state mangement system.
 *
 * The data structure is hierachal (ex: project -> requirementApplications -> reviews) which means that
 * invalidating a project invalidates all related data.
 *
 * To add a new path you will probably only need to add it to the general paths section and the admin/ basic
 * invalidations will be auto generated. In more complex or non-standard cases you can also write each of these
 * parts yourself or customize them.
 *
 * Usage:
 *   import keys from "queries/keys";
 *   keys.projects(1);
 *   keys.admin.projects(1);
 *
 * This covers use cases like:
 *   get/load one thing
 *   get/load all things of type
 *   all the above but for admin data structures
 */

// type PathFunction = (..._args: any[]) => any[];

// base paths to be used with react-query. When id null they return the root path.
export default {
  projects: (id?: number) => ["projects", id].filter(Boolean) as string[],
  projectScope: (id: number) => ["projects", id, "scope"],
  projectValidate: (id: number, params: any) => [
    "projects",
    id,
    "validate",
    JSON.stringify(params),
  ],
  users: (id?: number) => ["users", id].filter(Boolean) as string[],
  geoms: (ids: number[]) => ["geoms", ...ids],
  requirementApplications: (projectID: number, id?: number) =>
    ["projects", projectID, "requirementApplications", id].filter(Boolean) as string[],
  requirementApplicationReviews: (projectID: number, raID: number, id?: number) =>
    ["projects", projectID, "requirementApplications", raID, "reviews", id].filter(
      Boolean,
    ) as string[],
  requirementApplicationRenewals: (projectID: number, id?: number) =>
    ["projects", projectID, "requirementApplications", id, "renewals"].filter(Boolean) as string[],
  requirementApplicationAssets: (projectID: number, raID: number) => [
    "projects",
    projectID,
    "requirementApplications",
    raID,
    "assets",
  ],
  requirementApplicationUsers: (projectID: number, raID: number) => [
    "projects",
    projectID,
    "requirementApplications",
    raID,
    "assigned_users",
  ],
  admin: {
    projects: (id?: number) => ["admin", "projects", id].filter(Boolean) as string[],
    requirementApplications: (projectID: number, id?: number) =>
      ["admin", "projects", projectID, "requirementApplications", id].filter(Boolean) as string[],
    requirementApplicationReviews: (projectID: number, raID: number, id?: number) =>
      ["admin", "projects", projectID, "requirementApplications", raID, "reviews", id].filter(
        Boolean,
      ) as string[],
    requirementApplicationUsers: (projectID: number, raID: number) => [
      "admin",
      "projects",
      projectID,
      "requirementApplications",
      raID,
      "assigned_users",
    ],
  },
};
