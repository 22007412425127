import * as R from "ramda";
import { createSelector } from "reselect";

import { SET_GUIDES } from "actions";
import { selectFixedGuideID } from "reducers/ui";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_GUIDES:
      return R.mergeRight(state, action.payload);
    default:
      return state;
  }
};

export const selectGuideByID = (state, id) => R.path(["guides", id], state);
export const selectGuideBySlug = (state, slug) =>
  R.find(R.propEq(slug, "slug"), R.values(R.prop("guides", state)));

export const selectFixedGuide = (state) => {
  const fixedGuideID = selectFixedGuideID(state);
  return fixedGuideID && selectGuideByID(state, fixedGuideID);
};

export const selectGuides = createSelector(R.prop("guides"), R.values);

export const selectGuideByType = (state, type) =>
  R.find(R.propEq(type, "guide_type"), selectActiveGuides(state));

export const selectActiveGuides = (state) =>
  R.filter(R.propEq(true, "active"), R.values(state.guides));

export const selectVisibleGuides = (state) =>
  R.reject((g) => g.hidden || g.type === "EntryPoint", selectActiveGuides(state));

export const getRequirementIDs = R.propOr([], "requirement_ids");
export const getHandoffSections = R.propOr([], "handoff_sections");
export const getMessage = R.prop("message");
export const getHighlightMessageMarkdown = R.prop("highlight_message_markdown");
export const getType = R.prop("guide_type");
export const getTitle = R.prop("title");
export const getName = R.prop("name");
export const getDescription = R.prop("description");
export const getCallToAction = R.prop("call_to_action");
export const getSubmissionTimeframeMarkdown = R.prop("submission_timeframe_markdown");
export const getGuidePages = R.compose(R.sortBy(R.prop("sort_order")), R.propOr([], "guide_pages"));

export const getGuidePageBySlug = (guide, slug) =>
  R.find(R.propEq(slug, "slug"), R.prop("guide_pages", guide));

export const isZoning = R.propEq("zoning", "guide_type");
export const isDirectApplication = R.propEq("direct_application", "guide_type");
export const isRequirementCatalog = R.propEq("requirement_catalog", "guide_type");
export const overridesRequirementCalculation = R.prop("override_requirement_calculation");
export const getHandoffInstructions = R.prop("handoff_instructions_markdown");
export const getExternalHelpURL = R.prop("external_help_url");
export const getUseCodeFilters = R.propOr([], "use_code_filters");

const ensureEndsWithPortal = (s) => s && (s.endsWith(" Portal") ? s : `${s} Portal`); // HACK: this is fragile and should eventually be resolved through data

export const selectGuideNamesWithPortalById = R.compose(
  R.map(R.compose(ensureEndsWithPortal, getName)),
  R.prop("guides"),
);
