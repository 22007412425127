import React, { useCallback, useEffect, useRef, useState } from "react";

import { LinkEmphasis, SmallButton, SmallButtonBordered } from "components/Button";
import Icon from "components/Icon";
import SafePortal from "components/SafePortal";
import Tooltip from "components/Tooltip";
import SubscribeUsers from "components/admin/messages/SubscribeUsers";
import Message from "components/messaging/Message";
import FlashMessage from "components/shared/FlashMessage";
import Text, { useGetText } from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import { useSession } from "contexts/session";
import useScrollToHash, { waitForHashToScroll } from "hooks/useScrollToHash";
import useToggleState from "hooks/useToggleState";
import {
  useAssignedUserIDs,
  useMarkAllMessagesProcessed,
  useMessages,
  useSubscribeToMessages,
  useUnsubscribe,
} from "queries/messages";
import { formatAsLongDay, formatAsLongDayNoYear, now, parseTime } from "utils/time";

import NoMessages from "./NoMessages";
import styles from "./ProjectMessages.scss";
import SendMessageFooter from "./SendMessageFooter";

export const Messages = ({ adminView = false }) => {
  const { user } = useSession();
  const tenant = useCurrentTenantInfo();
  const getText = useGetText();
  const {
    record: { id, user: projectUser },
  } = useAnswerContext();
  const { data: messages = [], isLoading } = useMessages(id);
  const unreadMessages = messages.filter((message) => !message.read_at);

  useScrollToHash({ on: () => messages.length > 0 });
  const { value: assigning, setOn, setOff } = useToggleState(false);
  const contentRef = useRef();

  const messagesByDate = messages.reduce((dates, message) => {
    const time = parseTime(message.created_at);
    const date = now().year === time.year ? formatAsLongDayNoYear(time) : formatAsLongDay(time);
    dates[date] = dates[date] || [];
    dates[date].push(message);
    return dates;
  }, {});

  const { mutate: subscribe } = useSubscribeToMessages(id);
  const { mutate: unsubscribe } = useUnsubscribe(id);
  const { data: subscribedUserIDs = [] } = useAssignedUserIDs(id, { enabled: adminView });
  const subscribed = subscribedUserIDs.includes(user.id);
  const toggleSubscribe = useCallback(
    () => (subscribed ? unsubscribe() : subscribe()),
    [subscribed, subscribe, unsubscribe],
  );
  const { mutate } = useMarkAllMessagesProcessed(id);
  const markAllAsProcessed = useCallback(() => mutate(), [mutate]);
  const afterSubmit = useCallback((message) => waitForHashToScroll(`message-${message.id}`), []);
  const scrollToLast = useCallback(() => {
    if (messages.length) {
      waitForHashToScroll(`message-${messages[messages.length - 1]?.id}`);
    }
  }, [messages]);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      const { bottom } = element.getBoundingClientRect();
      const hasOverflow = bottom > window.innerHeight - 150;
      setHasScrollbar(hasOverflow);
    }
  }, [messages]);

  const SubscribeButton = subscribed ? SmallButton : SmallButtonBordered;
  const messagingEnabled = !user?.is_guest && (!adminView || tenant.messaging_enabled);

  if (isLoading || !projectUser) return <div />;

  if (messagingEnabled && projectUser.is_guest && adminView && messages.length === 0)
    return (
      <NoMessages
        admin={adminView}
        message="Messages cannot be sent unless the user creates an account"
      />
    );

  return (
    <>
      {messages.length === 0 && <NoMessages admin={adminView} />}
      {assigning && <SubscribeUsers onClose={setOff} />}
      {messagingEnabled && projectUser.is_guest && adminView && (
        <FlashMessage hideClose heading="Messaging is not available for this project">
          Messages cannot be sent unless the user creates an account. Please use email instead.
        </FlashMessage>
      )}
      {hasScrollbar && (
        <LinkEmphasis
          className={styles.jumpToLatest}
          onClick={scrollToLast}
          label={
            <>
              <span>
                <Text t="messages.jump_to_latest" />
              </span>
              <Icon icon="down-to-line" size="lg" />
            </>
          }
        />
      )}
      <div className={styles.messagesContent} ref={contentRef}>
        {Object.keys(messagesByDate).map((date) => (
          <div key={date} className={styles.messagesForDate}>
            <DateLine date={date} />
            <div className={styles.messageList}>
              {messagesByDate[date].map((message) => (
                <Message key={message.id} message={message} adminView={adminView} />
              ))}
            </div>
          </div>
        ))}
        {adminView && messages.length > 0 && (
          <div className={styles.subscribeAssignActions}>
            {messagingEnabled && (!projectUser.is_guest || !adminView) && (
              <>
                <Tooltip
                  icon={null}
                  message={
                    subscribed ? getText("messages.unsubscribe") : getText("messages.subscribe")
                  }
                >
                  <SubscribeButton onClick={toggleSubscribe}>
                    <Icon icon="mailbox" />
                    <span>{subscribed ? "Unsubscribe" : "Subscribe"}</span>
                  </SubscribeButton>
                </Tooltip>
                <Tooltip icon={null} message={getText("messages.assign")}>
                  <SmallButtonBordered onClick={setOn}>
                    <Icon icon="users" />
                    <span>Assign subscriptions</span>
                  </SmallButtonBordered>
                </Tooltip>
              </>
            )}
            {unreadMessages.length >= 1 && (
              <SmallButtonBordered onClick={markAllAsProcessed}>
                <Icon icon="check-double" />
                <span>Mark all as processed</span>
              </SmallButtonBordered>
            )}
          </div>
        )}
      </div>

      {messagingEnabled && (!projectUser.is_guest || !adminView) && (
        <SafePortal targetID="fixed-footer">
          <SendMessageFooter
            adminView={adminView}
            afterSubmit={afterSubmit}
            showHeading={!adminView}
            showTooltip={!adminView && messages?.length > 0}
          />
        </SafePortal>
      )}
    </>
  );
};

const DateLine = ({ date }) => (
  <div className={styles.dateLine}>
    <div className={styles.hr} />
    <div className={styles.date}>{date}</div>
  </div>
);

export default Messages;
