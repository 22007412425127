import React from "react";
import classnames from "classnames";
import * as R from "ramda";
import { useSelector } from "react-redux";

import Icon from "components/Icon";
import Markdown from "components/Markdown";
import { FeeTotal, RequirementTotal, RequirementsTotal } from "components/projects/FeeTotals";
import { useActiveToggle } from "components/utilities/withActiveToggle";
import withInspectable from "components/utilities/withInspectable";
import Text from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { selectCurrentFees, selectUnansweredFieldCountForPageSlug } from "reducers/projects";
import { getDescription, selectRequirementsByID } from "reducers/requirements";
import { selectMunicipalityType } from "reducers/tenant";
import analytics from "services/analytics";
import { REQUIREMENT_INFO_EXPANDED } from "services/tracking";
import { maybeMoney } from "utils/format";
import { orderByStringAttr } from "utils/func";

import { ButtonDiv } from "../accessibility/Div";
import styles from "./SummaryTable.scss";

const trackClickRequirementRow = ({ active, contextType }) =>
  analytics.track(REQUIREMENT_INFO_EXPANDED, {
    contextType,
    action: active ? "hide" : "show",
  });

const RequirementName = withInspectable(
  Object.assign(
    ({ requirement }) => (
      <div data-requirement-name className={styles.title} data-requirement-slug={requirement.slug}>
        {requirement.name}
      </div>
    ),
    { displayName: "RequirementName" },
  ),
);

const RequirementRow = ({
  requirement,
  fees,
  contextType,
  isAriaHidden,
  interactive,
  hideTotal,
  animate,
  showFees,
}) => {
  const { active, toggleActive } = useActiveToggle();
  const hasDetails = (interactive && requirement.description) || (showFees && fees.length > 0);
  return (
    <div
      data-requirement
      key={requirement.id}
      className={classnames(styles.requirementRowContainer, {
        [styles.active]: active,
      })}
      role="listitem"
    >
      <ButtonDiv
        onClick={() => {
          toggleActive();
          trackClickRequirementRow({ active, contextType });
        }}
        aria-expanded={active}
        isAriaHidden={isAriaHidden}
      >
        <div className={styles.requirementRow}>
          <RequirementName requirement={requirement} identifier={requirement.identifier} />
          {showFees && (
            <RequirementTotal requirementID={requirement.id} animate={animate} hideZero />
          )}
          {hasDetails && (
            <div className={styles.openItem}>
              <Icon icon={active ? "angle-up" : "angle-down"} size="lg" />
            </div>
          )}
        </div>

        {hasDetails && (
          <div className={classnames([styles.row, styles.open])}>
            {interactive && requirement.description && (
              <Markdown source={getDescription(requirement)} />
            )}

            {showFees && (
              <div className={styles.feeListings}>
                {fees.map((fee) => (
                  <FeeRow key={fee.id} fee={fee} identifier={fee.identifier} animate={animate} />
                ))}
                {!hideTotal && (
                  <div className={styles.requirementTotal}>
                    <div className={styles.spacer} />
                    <div data-requirement-fees-total className={styles.feeRow}>
                      <div className={styles.price}>
                        <RequirementTotal
                          requirementID={requirement.id}
                          animate={animate}
                          hideZero
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </ButtonDiv>
    </div>
  );
};

const FeeRow = withInspectable(
  Object.assign(
    ({ fee, animate }) => (
      <div data-fee className={styles.feeRow}>
        <div data-fee-name data-fee-slug={fee.slug} className={styles.subtitle}>
          {fee.name}
          {fee.interval && fee.interval !== "One-Time" && (
            <div className={styles.feeInterval}>
              <Text t="guides.summary_table.recurs" interval={fee.interval.toLowerCase()} />
            </div>
          )}
        </div>
        <div data-fee-amount className={styles.price}>
          <FeeTotal feeID={fee.id} animate={animate} hideZero />
        </div>
      </div>
    ),
    {
      displayName: "FeeRow",
    },
  ),
);

const NoRequirementRow = ({ isAriaHidden }) => {
  const { active, toggleActive } = useActiveToggle();
  return (
    <div
      className={classnames(styles.requirementRowContainer, {
        [styles.active]: active,
      })}
      role="listitem"
    >
      <ButtonDiv onClick={toggleActive} aria-expanded={active} isAriaHidden={isAriaHidden}>
        <div className={styles.requirementRow}>
          <div data-requirement-name className={styles.title}>
            <Text t="guides.summary_table.no_requirements_required" />
          </div>
          <div>$0.00</div>
          <div className={styles.openItem}>
            <Icon icon={active ? "angle-up" : "angle-down"} size="lg" />
          </div>
        </div>

        <div className={classnames([styles.row, styles.open])}>
          <Text t="guides.summary_table.no_requirements_required_description" />
        </div>
      </ButtonDiv>
    </div>
  );
};

const AccuracySectionExternalFee = () => (
  <p>
    <Text t="guides.summary_table.cost_varies_message_1" />{" "}
    <Text t="guides.summary_table.cost_varies_message_2" />
  </p>
);
const ACCURACY_LEVELS = {
  NONE: "NONE",
  PARTIAL: "PARTIAL",
  COMPLETE: "COMPLETE",
};

const getAccuracy = (unansweredCount) => {
  if (unansweredCount > 0) return ACCURACY_LEVELS.PARTIAL;
  if (unansweredCount === 0) return ACCURACY_LEVELS.COMPLETE;
  return ACCURACY_LEVELS.NONE;
};

const AccuracySection = ({ project, fees, requirements, unansweredFeeFieldsCount }) => {
  const hasExternalFees = requirements.some((r) => r.fees_calculated_externally);
  const defaultUnansweredFeeFieldsCount = useSelector((state) =>
    selectUnansweredFieldCountForPageSlug(state, project, "fees"),
  );
  if (unansweredFeeFieldsCount === null) unansweredFeeFieldsCount = defaultUnansweredFeeFieldsCount;

  const municipalityType = useSelector(selectMunicipalityType);
  const accuracy = getAccuracy(unansweredFeeFieldsCount);

  if (accuracy === ACCURACY_LEVELS.NONE) return null;
  if (accuracy === ACCURACY_LEVELS.PARTIAL && fees.length > 0)
    return (
      <div className={styles.accuracy}>
        <div className={classnames([styles.row, styles.accuracySection])}>
          <UnansweredFeeFieldsMessage
            hasExternalFees={hasExternalFees}
            unansweredCount={unansweredFeeFieldsCount}
          />
        </div>
      </div>
    );

  if (hasExternalFees)
    return (
      <div className={styles.accuracy}>
        <div className={classnames([styles.accuracySection, styles.row])}>
          <Icon icon="check-circle" className={styles.success} />
          <div>
            <div className={styles.accuracyMessage}>
              <AccuracySectionExternalFee />
            </div>
          </div>
        </div>
      </div>
    );

  if (fees.length === 0) return null;

  return (
    <div className={styles.accuracy}>
      <div className={classnames([styles.accuracySection, styles.row])}>
        <Icon icon="check-circle" className={styles.success} />
        <div>
          <div className={styles.accuracyMessage}>
            <Text t="guides.summary_table.complete_message" municipalityType={municipalityType} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GrandTotal = ({ requirementIDs, fees, animate }) => {
  const totalRecurringFees = fees.reduce(
    (total, f) => (total + f.interval === "One-Time" ? f.cost : 0),
    0,
  );

  return (
    <div className={styles.totalFees} data-grand-total>
      <div className={classnames([styles.row, styles.totalRow])}>
        <div className={styles.title}>
          <Text t="guides.summary_table.total_cost" />
        </div>
        <div data-project-fees-total>
          <RequirementsTotal requirementIDs={requirementIDs} animate={animate} hideZero />
        </div>
      </div>

      {totalRecurringFees > 0 && (
        <div className={classnames([styles.row, styles.totalRecurringFees])}>
          <div className={styles.title}>
            <Text t="guides.summary_table.recurring_cost" />
          </div>

          <div data-project-recurring-fees-total>{maybeMoney(totalRecurringFees)}</div>
        </div>
      )}
    </div>
  );
};

const UnansweredFeeFieldsMessage = ({ hasExternalFees = false, unansweredCount }) => (
  <>
    <div className={styles.calculator}>
      <Icon icon="circle-info" size="lg" />
    </div>
    <div className={styles.accuracyMessage}>
      <div>
        <Text t="guides.summary_table.partial_accuracy_message" unansweredCount={unansweredCount} />
      </div>
      {hasExternalFees && <AccuracySectionExternalFee />}
    </div>
  </>
);

export const MaybeUnansweredFeeFieldsMessage = () => {
  const { record: project } = useAnswerContext();
  const hasActiveFees = R.keys(project.calculated_fee_totals).length > 0;

  const unansweredCount = useSelector((state) =>
    selectUnansweredFieldCountForPageSlug(state, project, "fees"),
  );
  const accuracy = getAccuracy(unansweredCount);

  if (accuracy === ACCURACY_LEVELS.PARTIAL && hasActiveFees)
    return <UnansweredFeeFieldsMessage unansweredCount={unansweredCount} />;

  return null;
};

const TableLayout = ({ sticky, headerText, children }) => (
  <div
    role="list"
    data-summary-table
    className={classnames(styles.table, { [styles.sticky]: sticky })}
  >
    <div className={styles.printHeader}>
      <h2>
        <Text t="guides.summary.requirements_heading" />
      </h2>
      <hr />
    </div>
    <div className={styles.tableHeader}>
      {headerText || <Text t="guides.summary_table.requirements" />}
    </div>
    {children}
  </div>
);

const SummaryTable = ({
  requirementID,
  sticky,
  headerText,
  contextType = "summary",
  unansweredFeeFieldsCount = null,
  isAriaHidden = false,
  showAccuracy = true,
  interactive = true,
  animate = false,
  showFees = true, // only used by requirement guide page?
}) => {
  const { record: project } = useAnswerContext();
  const requirementIDs = requirementID ? [requirementID] : project.requirement_ids || [];
  const fees = useSelector((state) =>
    selectCurrentFees(state, project).filter((f) => requirementIDs.includes(f.requirement_id)),
  );
  const requirements = useSelector((state) =>
    orderByStringAttr(selectRequirementsByID(state, requirementIDs), "name"),
  );

  if (requirements.length === 0)
    return (
      <TableLayout headerText={headerText} sticky={sticky}>
        <NoRequirementTable project={project} showFees={showFees} isAriaHidden={isAriaHidden} />
      </TableLayout>
    );

  const feesByRequirement = R.groupBy(R.prop("requirement_id"), fees);

  return (
    <TableLayout sticky={sticky} headerText={headerText}>
      <div className={styles.requirementRows}>
        {requirements.map((requirement) => (
          <RequirementRow
            key={requirement.id}
            identifier={requirement.identifier}
            requirement={requirement}
            fees={feesByRequirement[requirement.id] || []}
            contextType={contextType}
            isAriaHidden={isAriaHidden}
            interactive={interactive}
            hideTotal={requirements.length <= 1}
            animate={animate}
            showFees={showFees}
          />
        ))}
      </div>

      {showFees && fees.length > 0 && (
        <GrandTotal requirementIDs={requirementIDs} fees={fees} animate={animate} />
      )}

      {showFees && showAccuracy && (
        <AccuracySection
          project={project}
          unansweredFeeFieldsCount={unansweredFeeFieldsCount}
          fees={fees}
          requirements={requirements}
        />
      )}
    </TableLayout>
  );
};

const NoRequirementTable = ({ project, isAriaHidden = false, showFees }) => {
  const unansweredRequirementFieldsCount = useSelector((state) =>
    selectUnansweredFieldCountForPageSlug(state, project, "requirements"),
  );
  return (
    <>
      {showFees && unansweredRequirementFieldsCount === 0 && (
        <div className={styles.requirementRows}>
          <NoRequirementRow isAriaHidden={isAriaHidden} />
          <div className={styles.totalFees}>
            <div className={classnames([styles.row, styles.totalRow])}>
              <div className={styles.title}>
                <Text t="guides.summary_table.total_cost" />
              </div>

              <div data-project-fees-total>$0.00</div>
            </div>
          </div>
        </div>
      )}
      {unansweredRequirementFieldsCount > 0 && (
        <div className={styles.messageContainer}>
          <Text t="guides.summary_table.no_requirements_incomplete_message" />
        </div>
      )}
      {unansweredRequirementFieldsCount === 0 && (
        <div className={classnames(styles.noRequirementsContainer, styles.messageContainer)}>
          <Icon icon="times-octagon" />
          <div>
            <div className={styles.title}>
              <Text t="guides.summary_table.no_requirements_header" />
            </div>
            <Text t="guides.summary_table.no_requirements_message" />
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryTable;
