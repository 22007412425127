import React from "react";

import { useGetText } from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import { useCurrentTenantInfo } from "contexts/currentTenant";
import inboxImage from "images/inbox.svg";
import { useGuide } from "queries/guides";
import { isBlank } from "utils/func";

import styles from "./NoMessages.scss";

const NoMessages = ({ admin, message }) => {
  const {
    record: { guide_id: guideID },
  } = useAnswerContext();
  const { data: guide } = useGuide(guideID, { enabled: !isBlank(guideID) });
  const getText = useGetText();
  const tenant = useCurrentTenantInfo();
  const responseTimeMessage = isBlank(guide?.messaging_response_time_copy)
    ? getText("guides.messaging.response_time_copy_default")
    : guide?.messaging_response_time_copy;
  const visibleResponseTimeMessage = admin ? null : responseTimeMessage;

  message ||= admin ? getText("messages.use_form_admin") : getText("messages.use_form");

  return (
    <div className={styles.noMessages}>
      <img src={inboxImage} alt={getText("messages.no_messages")} />
      <h3>{getText("messages.project_no_messages")}</h3>
      {tenant.messaging_enabled && (
        <>
          <div>{message}</div>
          <br />
          <div>{visibleResponseTimeMessage}</div>
        </>
      )}
    </div>
  );
};

export default NoMessages;
