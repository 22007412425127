import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import * as R from "ramda";

import Icon from "components/Icon";
import { toDisplayURL } from "utils/urls";

import styles from "./ExternalLink.scss";

const stopPropagate = (e) => e.stopPropagation();

const ExternalLink = ({
  href,
  children = undefined,
  className = undefined,
  hideIcon = false,
  showIcon = false,
  disabled = false,
  target = "_blank",
  ...props
}) => {
  if (!href || R.isEmpty(href)) {
    if (children) return <span className={className}>{children}</span>;
    return null;
  }
  return (
    <a
      className={className}
      target={target}
      rel="noopener noreferrer"
      href={disabled ? null : href}
      onClick={stopPropagate}
      {...props}
    >
      {children}
      {!children && (
        <span className={styles.defaultContent}>
          <span className={styles.displayUrl}>{toDisplayURL(href)}</span>
          <span className={styles.hideExceptPrinting}>{href}</span>
        </span>
      )}
      {!hideIcon && (
        <Icon
          className={classnames(styles.externalLinkIcon, {
            [styles.alwaysShow]: showIcon,
          })}
          icon="external-link"
        />
      )}
    </a>
  );
};
ExternalLink.propTypes = {
  href: PropTypes.string,
  hideIcon: PropTypes.bool,
};

export default ExternalLink;
