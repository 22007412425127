import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { useFormState } from "react-hook-form";
import { Link } from "react-router-dom-v5-compat";

import Text from "containers/Text";
import { isPromise } from "utils/func";

import styles from "./Button.scss";
import { InlineLoader } from "./Loader";

type ButtonType =
  | "btn"
  | "btn-bordered"
  | "btn-sm"
  | "btn-bordered-sm"
  | "btn-wide"
  | "btn-txt"
  | "link-emphasis";
interface ButtonProps {
  label?: any;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: (e: any) => void;
  withSpinner?: boolean;
  expandOnMobile?: boolean;
  confirmMessage?: string;
  href?: string;
}

const makeButton = (buttonType: ButtonType) => {
  const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        label,
        disabled,
        isLoading,
        withSpinner = false,
        expandOnMobile,
        children,
        onClick,
        className,
        confirmMessage,
        href,
        ...buttonProps
      },
      ref,
    ) => {
      const [wasClicked, setWasClicked] = useState(false);

      if (!onClick && href) {
        onClick = () => {
          window.location.href = href;
        };
      }

      const onClickWrapper = useCallback(
        async (e) => {
          try {
            e.stopPropagation();
            setWasClicked(true);
            let retval;

            // eslint-disable-next-line no-alert
            if (onClick && (!confirmMessage || window.confirm(confirmMessage))) {
              retval = onClick(e);
            }
            if (isPromise(retval)) {
              // when the  handler is async, the spinner reflects its state; otherwise the spinner goes forever (expecting its action would result in unrendering this Button)
              await retval;
            }
          } finally {
            setWasClicked(withSpinner);
          }
        },
        [onClick, confirmMessage, withSpinner],
      );

      const showLoader = isLoading || (withSpinner && wasClicked);

      return (
        <button
          type={onClick ? "button" : "submit"}
          className={classnames(buttonType, className, {
            [styles.disabled]: disabled,
          })}
          disabled={disabled || showLoader}
          onClick={onClickWrapper}
          ref={ref}
          {...buttonProps}
        >
          <span
            className={classnames(styles.textContainer, {
              [styles.expandOnMobile]: expandOnMobile,
              [styles.disabled]: disabled,
            })}
          >
            {showLoader && <Loader disabled={disabled} />}
            <span
              className={classnames(styles.text, {
                [styles.loading]: showLoader,
              })}
            >
              {label}
              {children}
            </span>
          </span>
        </button>
      );
    },
  );
  Button.displayName = "Button";
  return Button;
};

const Loader = ({ disabled }) => (
  <div className={styles.loaderContainer}>
    <div className={styles.loader}>
      <InlineLoader inverse={disabled} />
    </div>
  </div>
);

export const SmallButton = makeButton("btn-sm");
export const Button = makeButton("btn");
export const SmallButtonBordered = makeButton("btn-bordered-sm");

export const TextButton = makeButton("btn-txt");
export const WideButton = makeButton("btn-wide");

export const AdminButton = makeButton("btn"); // deprecated
export const ButtonBordered = makeButton("btn-bordered");

export const LinkEmphasis = makeButton("link-emphasis");

/**
 * For use with react-hook-form forms. It will submit the form and handle submitting / disabled states.
 *
 * @param {object} props
 * @param {object} props.formControl - react-hook-form useHookForm's control return value. Optional if used within a FormContext
 * @param {boolean} props.allowSubmitInvalid
 * @param {boolean} props.allowSubmitNonDirty
 * @returns
 */
export const SubmitButton = ({
  formControl,
  children,
  allowSubmitNonDirty,
  allowSubmitInvalid,
  ...props
}) => {
  const { isDirty, isValid, isSubmitting } = useFormState({ control: formControl });
  const disabled = !(isDirty || allowSubmitNonDirty) || !(isValid || allowSubmitInvalid);

  return (
    <Button disabled={disabled} isLoading={isSubmitting} {...props}>
      {children || <Text t="helpers.titles.save" />}
    </Button>
  );
};

export const LinkButton = ({ to, disabled, children, className, ...linkProps }) =>
  disabled ? (
    <div className={classnames("btn", "disabled", className, styles.disabled)} {...linkProps}>
      {children}
    </div>
  ) : (
    <Link to={to} className={classnames("btn", className, {})} {...linkProps}>
      {children}
    </Link>
  );
