import { PayableAccount } from "types/entities";

import resourceOperations, { ReadOptions } from "./resourceOperations";

const { useRead } = resourceOperations({
  basePath: "/api/payable_accounts",
  baseKey: "payableAccounts",
});

export const useInPersonPayableAccount = (id: number, opts: ReadOptions<PayableAccount> = {}) =>
  useRead<PayableAccount>(id, { staleTime: Infinity, ...opts });
