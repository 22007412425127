import React, { useCallback } from "react";
import classnames from "classnames";
import * as R from "ramda";

import styles from "./Inspector.scss";

const InspectableComponent = ({ inspectorContext, identifier, answerContext, children }) => {
  const selected = R.pathEq(identifier, ["inspectedEntity", "identifier"], inspectorContext);

  const onSelect = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      inspectorContext.onSelect({ answerContext, identifier });
    },
    [answerContext, identifier, inspectorContext],
  );

  return (
    <div
      className={classnames(styles.inspectable, {
        [styles.selected]: selected,
      })}
      onClickCapture={onSelect}
    >
      {children}
    </div>
  );
};

export default InspectableComponent;
