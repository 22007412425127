import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import * as R from "ramda";
import { useForm, useFormContext } from "react-hook-form";
import { Link, useLocation } from "react-router-dom-v5-compat";

import { LinkEmphasis, SmallButton, SmallButtonBordered } from "components/Button";
import Icon from "components/Icon";
import Popover from "components/Popover";
import Tooltip from "components/Tooltip";
import { Select2Input, TextAreaInput, withController } from "components/forms/HookFields";
import { useGetText } from "containers/Text";
import { useAnswerContext } from "containers/withAnswerContext";
import FormProvider from "contexts/form";
import useToggleState from "hooks/useToggleState";
import { useGuide } from "queries/guides";
import { useSendMessage } from "queries/messages";
import { useRequirementApplication } from "queries/requirementApplications";
import { useRequirement } from "queries/requirements";
import { formatBytes } from "utils/format";
import { EMPTY_ARRAY, isBlank } from "utils/func";
import routes from "utils/routes";
import upload from "utils/upload";

import Attachment from "./Attachment";
import styles from "./SendMessage.scss";

const useAttachments = (onChange, value) => {
  const fileRef = useRef(null);

  const selectFiles = useCallback(() => fileRef.current.click(), []);

  const onAttach = useCallback(
    async (change) => {
      change.persist();
      const files = Array.from(change.target.files);
      const attachments = await Promise.all(
        files.map(async (file) => {
          const attachmentID = await upload(file);
          return {
            signed_id: attachmentID,
            filename: file.name,
            content_type: file.type,
            size: formatBytes(file.size),
          };
        }),
      );
      const allAttachments = value ? [...value, ...attachments] : attachments;

      onChange(allAttachments);
    },
    [onChange, value],
  );

  return { fileRef, selectFiles, onAttach };
};

const AttachmentButton = withController(
  ({ value, onChange, forwardedRef: _, disabled, ...inputProps }) => {
    const { fileRef, selectFiles, onAttach } = useAttachments(onChange, value);

    return (
      <SmallButtonBordered onClick={selectFiles} disabled={disabled}>
        <Icon icon="paperclip" />
        <input
          type="file"
          {...inputProps}
          ref={fileRef}
          onChange={onAttach}
          style={{ display: "none" }}
          multiple
        />
      </SmallButtonBordered>
    );
  },
);

const ApplicationsSelect = ({ onClose, applications }) => (
  <Select2Input
    name="context_id"
    options={applications}
    classNames={{
      control: () => styles.applicationsControl,
      option: () => styles.applicationsOption,
      container: () => styles.applicationsContainer,
      menu: () => styles.applicationsMenu,
      menuList: () => styles.applicationsMenuList,
    }}
    label="Application"
    placeholder="Type to search applications"
    menuPosition="absolute"
    onBlur={onClose}
    onMenuClose={onClose}
    defaultMenuIsOpen
    openMenuOnFocus
    autoFocus
    unstyled
  />
);

const AssignApplication = () => {
  const { value: isOpen, toggle, setOff: onClose } = useToggleState();
  const buttonRef = useRef(null);

  const {
    record: { requirement_applications: requirementApplications },
  } = useAnswerContext();
  const applications = R.values(requirementApplications).map((application) => ({
    value: application.id,
    label: application.requirement_name,
  }));

  const getText = useGetText();

  if (applications.length === 0) {
    return null;
  }

  return (
    <>
      <SmallButtonBordered onClick={toggle} ref={buttonRef}>
        <Tooltip icon={null} message={getText("messages.link_application")} disabled={isOpen}>
          <Icon icon="file-lines" />
          <span>
            <Icon icon="triangle" faStyle="solid" />
          </span>
        </Tooltip>
      </SmallButtonBordered>
      {isOpen && (
        <Popover referenceElement={buttonRef.current} placement="top">
          <ApplicationsSelect applications={applications} onClose={onClose} />
        </Popover>
      )}
    </>
  );
};

export const ApplicationLink = ({ applicationID, adminView }) => {
  const { record: { id: projectID } = {} } = useAnswerContext();
  const { data: requirementApplication } = useRequirementApplication(projectID, applicationID);
  const { data: requirement } = useRequirement(requirementApplication?.requirement_id, {
    enabled: !!requirementApplication?.requirement_id,
  });
  const link = adminView
    ? routes.admin.requirementApplication(projectID, applicationID)
    : routes.requirementApplication(projectID, requirement?.id);

  return (
    <div className={styles.applicationLink} data-application-link>
      <Icon icon="file-lines" />
      <Link to={link}>{requirement?.name}</Link>
    </div>
  );
};

const ApplicationPreview = ({ applicationID, adminView }) => {
  const { resetField } = useFormContext();
  const removeApplicationLink = useCallback(() => {
    resetField("context_id");
  }, [resetField]);

  return (
    <div className={styles.applicationPreview}>
      <ApplicationLink applicationID={applicationID} adminView={adminView} />
      {adminView && <LinkEmphasis label="remove" onClick={removeApplicationLink} />}
    </div>
  );
};

const SendMessage = ({ adminView, showHeading = false, showTooltip = true, afterSubmit = R.T }) => {
  const {
    record: { id, guide_id: guideID },
    readOnly,
  } = useAnswerContext();
  const { data: guide } = useGuide(guideID, { enabled: !isBlank(guideID) });
  const { mutate: sendMessage } = useSendMessage(id);
  const formMethods = useForm();
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isValid },
  } = formMethods;
  const attachments = watch("attachments") || EMPTY_ARRAY;
  const applicationID = watch("context_id");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.linkApplication) {
      setValue("context_id", location.state.linkApplication);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const onSend = async (formData) => {
    sendMessage(formData, {
      onSuccess: (message) => {
        afterSubmit(message);
      },
    });
    reset();
  };

  const onSubmit = handleSubmit(onSend);

  const removeAttachment = useCallback(
    (idx) => {
      setValue(
        "attachments",
        attachments.filter((_, i) => i !== idx),
      );
    },
    [setValue, attachments],
  );

  const getText = useGetText();

  const responseTimeMessage = isBlank(guide?.messaging_response_time_copy)
    ? getText("guides.messaging.response_time_copy_default")
    : guide?.messaging_response_time_copy;

  const responseTimeTooltip = showTooltip ? (
    <>
      {" "}
      <Tooltip
        icon="info-circle"
        message={responseTimeMessage}
        direction="top"
        testID="response-time-tooltip"
      />
    </>
  ) : null;

  return (
    <FormProvider {...formMethods}>
      <div
        className={classnames(styles.footerContainer, { [styles.adminFooterContainer]: adminView })}
      >
        <div className={styles.footerContent}>
          {showHeading && (
            <div className={styles.heading}>
              {getText("messages.heading")}
              {responseTimeTooltip}
            </div>
          )}
          {applicationID && (
            <ApplicationPreview applicationID={applicationID} adminView={adminView} />
          )}
          <div className={styles.fieldContainer}>
            <div className={styles.field}>
              <TextAreaInput
                name="message"
                placeholder={getText("messages.enter_message")}
                rows={adminView ? 5 : 2}
                disabled={readOnly && !adminView}
              />
            </div>
            <div className={styles.actions}>
              <Tooltip icon={null} message={getText("messages.attach")}>
                <AttachmentButton name="attachments" disabled={readOnly && !adminView} />
              </Tooltip>
              {adminView && (
                <div className={styles.adminButtons}>
                  <AssignApplication />
                </div>
              )}
              <div>
                <SmallButton
                  onClick={onSubmit}
                  disabled={!isDirty || !isValid}
                  className={styles.sendButton}
                  data-send-message
                >
                  {getText("messages.send")}&nbsp;
                  <Icon icon="paper-plane-top" />
                </SmallButton>
              </div>
            </div>
          </div>
          {attachments.length > 0 && (
            <div className={styles.attachments}>
              {attachments.map((attachment, idx) => (
                <div className={styles.pendingAttachment} key={attachment.signed_id}>
                  <Attachment attachment={attachment} />
                  <LinkEmphasis label="remove" onClick={() => removeAttachment(idx)} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default SendMessage;
